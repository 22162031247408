/* Utilitiy classes */
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.spin {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
}


.d-flex {
  display: flex;
}

.flex-grid .flex-grid-child {
  padding: 0 12px;
}

.align-center-v {
  display: flex;
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.line-height-0 {
  line-height: 0;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.status {
  font-size: 12px !important;
  font-weight: bold !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: center !important;
  gap: 5px !important;
}

.status-success {
  background-color: #5FC69A;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  max-width: 6px;
  max-height: 6px;
}

.status-created {
  background-color: #CF4A40;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  max-width: 6px;
  max-height: 6px;
}

.status.warning {
  background-color: #fa8b0c;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  max-width: 6px;
  max-height: 6px;
}

.status-error {
  background-color: #CF4A40;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  max-width: 6px;
  max-height: 6px;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/* spacing classes */

.m-0 {
  margin: 0 !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-0 {
  padding-left: 0 !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.pr-0 {
  padding-right: 0 !important;
}

/* default card style */

.ant-card {
  box-shadow: 0 5px 20px #9299b803;
}

.ant-card-body {
  padding: 25px !important;
}

.ant-card-head {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ant-card-head-title span {
  display: inline-block;
  margin-left: 5px;
  font-size: 11px;
  font-weight: 500;
  color: #868eae;
}

.ant-card-head .ant-card-extra {
  display: flex;
  align-items: center;
}

.ant-card-head .ant-card-extra a {
  color: #868eae;
}

.ant-card-extra .ant-dropdown-trigger {
  line-height: 0;
  order: 1;
  margin-left: 20px;
}

.sDash_unresizable {
  resize: none;
}

/* ant radio group */

.ant-radio-button-wrapper-checked {
  color: #fff !important;
}

/* card nav */

.card-nav ul {
  list-style: none;
  display: flex;
  margin: 0 -8px !important;
}

.card-nav ul li {
  margin: 0 8px !important;
  position: relative;
}

.card-nav ul li a {
  font-weight: 500;
  color: #868eae;
  font-size: 12px;
}

.card-nav ul li.active a {
  color: var(--primary-buttons-color);
  font-weight: 500;
}

.card-nav ul li.active:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background: var(--primary-buttons-color);
  left: 0;
  bottom: -19px;
}

/* chart growth upward */

.growth-downward p,
.growth-upward p {
  font-size: 14px;
  color: #868eae;
  margin: 0;
}

.growth-downward h1 sub svg,
.growth-upward h1 sub svg {
  position: relative;
  top: 2px;
  font-size: 14px;
  font-weight: 600;
  left: 5px;
  bottom: 0;
}

/*
.growth-downward h1, .growth-upward h1 {
  font-size: 22px;
  margin: 6px 0 0;
} */

.growth-downward h1 sub {
  color: #ff4d4f;
}

.growth-upward h1 sub {
  color: #20c997;
}

/* Chart */

.chart-label {
  display: flex;
}

.chart-label .chart-label__single {
  align-items: center;
}

.chart-label .chart-label__single:not(:last-child) {
  margin-right: 40px;
}

.chart-label .chart-label__single p {
  margin: 0;
  color: #868eae;
}

/* revenue doughnut */

.revenue-doughnut {
  display: flex;
  justify-content: center;
}

.revenue-doughnut>div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.revenue-doughnut .rd-labels {
  width: 100%;
  margin-top: 30px;
}

.revenue-chat-percentage span {
  display: block;
}

.revenue-chat-percentage span:not(:last-child) {
  margin-bottom: 15px;
}

/* progressbars */

.ant-progress {
  display: inline-flex !important;
  align-items: center;
}

.ant-progress>div {
  display: flex;
  flex-direction: column;
}

.ant-progress .ant-progress-outer {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.revenue-chat-percentage span {
  display: block;
  font-size: 13px;
  color: #868eae;
}

.revenue-chat-percentage span:not(:last-child) {
  margin-bottom: 20px;
}

.ant-progress-status-warning .ant-progress-bg {
  background: #fa8b0c;
}

/* progress bars */

.progress-primary .ant-progress-bg {
  background: #202349;
}

.progress-secondary .ant-progress-bg {
  background: #ff69a5;
}

.progress-success .ant-progress-bg {
  background: #20c997;
}

.progress-success .progress-info .ant-progress-bg {
  background: #2c99ff;
}

.progress-warning .ant-progress-bg {
  background: #fa8b0c;
}

.progress-danger .ant-progress-bg {
  background: #ff4d4f;
}

/* color classes */

.color-primary {
  color: #2280F4;
}

.color-secondary {
  color: #ff69a5;
}

.color-info {
  color: #2c99ff;
}

.color-warning {
  color: #fa8b0c;
}

.color-success {
  color: #20c997;
}

.color-danger {
  color: #ff4d4f;
}

.color-dark {
  color: #272b41;
}

.color-error {
  color: #f5222d;
}

.color-gray {
  color: #5a5f7d;
}

/* Button Styles */

.ant-btn.ant-btn-light:focus {
  background: #fff;
  color: #5a5f7d;
}

.button-example .ant-btn {
  margin: 4px;
}

.button-example .ant-btn-group .ant-btn {
  margin: 0;
}

.ant-btn-white {
  color: #5a5f7d !important;
  border-color: #e3e6ef !important;
}

.ant-btn-white:hover {
  color: var(--primary-buttons-color) !important;
  background-color: #fff !important;
  border-color: #fff;
}

.ant-btn-white:focus {
  background-color: transparent !important;
}

.ant-btn-link {
  background-color: #fff;
  border-color: #fff;
  color: var(--primary-buttons-color) !important;
}

.ant-btn-link:hover {
  border-color: #fff;
  background: #fff;
}

.ant-btn-light,
.ant-btn-light:hover,
.ant-btn-link:hover,
.ant-btn-dashed {
  color: #5a5f7d !important;
}

.ant-btn-light {
  border-color: #e3e6ef;
  background: #f4f5f7 !important;
}

.ant-btn-light.btn-outlined {
  background: transparent !important;
}

.ant-btn-light.btn-transparent {
  background: #f4f5f715;
}

.ant-btn-dashed {
  border-width: 1px !important;
}

.ant-btn-dashed:hover {
  color: var(--primary-buttons-color);
  border: 1px dashed var(--primary-buttons-color);
}

.ant-btn-primary[disabled] {
  color: #fff;
  background: var(--disabled-buttons-color);
}

.ant-btn-light[disabled] {
  background: #fff;
}

.ant-btn-round.ant-btn-sm {
  height: 38px;
}

.ant-btn-white[disabled] {
  background-color: transparent;
  opacity: 0.6;
}

.ant-btn-white[disabled]:hover {
  color: #5a5f7d !important;
}

.ant-btn-primary[disabled]:hover {
  color: #fff !important;
  background: var(--disabled-buttons-color) !important;
}

.btn-icon {
  padding: 0 13px;
}

.btn-inc,
.btn-dec {
  height: 38px;
  width: 38px;
  font-size: 20px;
  padding: 0 12px !important;
  border-radius: 10px !important;
  border: 0 none;
}

.btn-inc:hover,
.btn-dec:hover {
  background: #2280F410 !important;
  border: 0 none !important;
}

.btn-inc:hover i,
.btn-inc:hover svg,
.btn-dec:hover i,
.btn-dec:hover svg {
  color: #2280F4;
}

/* input styles */

.ant-form-item-label>label {
  font-weight: 500;
}

.ant-picker-input>input::placeholder {
  color: #adb4d2 !important;
}

/* Calendar Styles */

.ant-picker-calendar-header .ant-select-selector {
  height: 32px !important;
}

.ant-picker-calendar-header .ant-select-selection-search-input {
  height: 30px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 30px !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #fff !important;
  color: var(--primary-buttons-color) !important;
}

/* pagination */

.ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px !important;
}

.ant-pagination-item-active {
  background-color: var(--primary-buttons-color) !important;
  border: none !important;
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination-item a {
  color: #fff !important;
}

.ant-pagination .ant-pagination-options .ant-select-selection-item {
  font-size: 13px;
  line-height: 30px !important;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
  height: 30px;
  line-height: 30px;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper input {
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-options .ant-select-selector {
  border: 0.5px solid #B5BAD2 !important;
  background-color: #fff;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  color: #5a5f7d !important;
  line-height: 2.6;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-link {
  display: block;
}

.ant-pagination-prev,
.ant-pagination-next {
  line-height: 28px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 0 none !important;
}

.ant-pagination .ant-pagination-item a {
  color: #5a5f7d;
}

.ant-pagination .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 30px !important;
  color: #5a5f7d;
}

.ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-pagination {
  margin: -4px -12px !important;
}

.ant-pagination-item,
.ant-pagination-options,
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-next {
  margin: 4px !important;
}

.ant-table-pagination {
  margin-top: 30px !important;
}

/* Wizard Modal */
.submission-successModal {
  text-align: center;
}

.submission-successModal .icon-success {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #20c997;
}

.submission-successModal p {
  margin-bottom: 0;
}

.submission-successModal .icon-success svg {
  color: #20c997;
}

.submission-successModal .submission-action {
  margin: 30px -5px -5px -5px;
}

.submission-successModal .submission-action button {
  margin: 5px;
  height: 38px;
}

/* Tree Select */

.ant-tree-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px !important;
  line-height: 40px;
}

.ant-tree-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-tree-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px !important;
}

/* radio style */

.ant-radio-checked .ant-radio-inner {
  border-width: 5px !important;
}

.ant-radio-inner::after {
  content: none !important;
}

/* Statistics */

.ant-statistic .ant-statistic-title {
  color: #9299b8;
}

.ant-statistic .ant-statistic-content span {
  font-size: 20px;
}

/* Steps */

.steps-action.justify-content-center {
  justify-content: center !important;
}

/* rate */

.ant-rate-star.ant-rate-star-zero span svg {
  color: #c6d0dc;
}

.ant-rate-star:not(:last-child) {
  margin-right: 2px !important;
}

.ant-rate-text {
  color: #5a5f7d;
}

/* result */

.ant-result-icon {
  margin-bottom: 20px !important;
}

.ant-result-title {
  font-weight: 500;
  margin-bottom: 10px;
}

.ant-result-extra {
  height: 34px;
  padding: 4px 10.72px;
}

.ant-result-content .ant-typography strong {
  font-weight: 500;
}

.ant-result-content .ant-typography:last-child {
  margin-bottom: 0;
}

/* form select */

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px !important;
  border-radius: 4px !important;
  border: 1px solid #e3e6ef !important;
}

.ant-select-single:not(.ant-select-customize-input):hover .ant-select-selector {
  border: 1px solid var(--primary-buttons-color) !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 33px !important;
}

.ant-select-focused:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--primary-buttons-color) !important;
  border-right-width: 1px !important;
  outline: 0 !important;
  box-shadow: 0 0 0 5px var(--inputs-focus-shadow-color) !important;
}

.ant-select-multiple .ant-select-selector {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.sDash_fullwidth-select {
  width: 100%;
}

/* Nasted Comments Styles */

.nested-comment-wrapper .comment-title {
  font-size: 12px;
  padding-bottom: 10px;
  margin-bottom: 22px;
  border-bottom: 1px solid #e3e6ef;
}

/* calendar style */

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}

.notes-month {
  text-align: center;
  font-size: 28px;
}

.notes-month section {
  font-size: 28px;
}

/* Breadcrumb demo */

.demo {
  margin: 16px;
}

.demo-nav {
  height: 30px;
  line-height: 30px;
  margin-bottom: 16px;
  background: #f8f8f8;
}

.demo-nav a {
  line-height: 30px;
  padding: 0 8px;
}

.app-list {
  margin-top: 16px;
}

/* Modal */

.ant-modal .ant-modal-content {
  border-radius: 10px;
}

.ant-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.ant-modal-body p:last-child {
  margin-bottom: 0;
}

.project-modal {
  padding: 5px !important;
}

.project-modal .projects-members {
  margin-top: -12px;
}

.project-modal .ant-form-item-control-input {
  margin-top: 10px;
  min-height: auto;
}

.project-modal .ant-select-selector {
  border-color: #e3e6ef;
}

.project-modal-footer {
  padding: 10px !important;
  text-align: left !important;
}

.sDash_addTask-modal {
  max-width: 390px;
}

.sDash_addTask-modal .ant-modal-header {
  border-bottom: 0 none;
  padding-bottom: 0 !important;
}

.sDash_addTask-modal .ant-modal-header .ant-modal-title {
  font-size: 16px;
  font-weight: 500;
  color: #272b41;
}

.sDash_addTask-modal .sDash_addTask-modal-inner .ant-form-item {
  margin-bottom: 15px;
}

.sDash_addTask-modal .ant-modal-header .ant-modal-close-x svg {
  color: #9299b8;
}

.sDash_addTask-modal .ant-form-item-control-input {
  min-height: 36px;
}

.sDash_addTask-modal input::placeholder,
.sDash_addTask-modal textarea::placeholder {
  color: #9299b8;
}

.sDash_addTask-modal input,
.sDash_addTask-modal textarea {
  padding: 6px 20px;
  border-radius: 5px;
}

.sDash_addTask-modal textarea {
  resize: none;
  min-height: 125px;
}

.sDash_addTask-modal .sDash-modal-actions {
  display: flex;
  justify-content: flex-end;
  margin: -6px;
}

.sDash_addTask-modal .sDash-modal-actions button {
  font-size: 14px;
  margin: 6px;
  height: 38px;
  border-radius: 5px;
}

/* slider */

.ant-slider-handle {
  margin-top: -6px !important;
}

/* tags */

.ant-tag {
  font-weight: 600;
  padding: 0 9.5px;
}

/* tabs */

.ant-tabs-tab span {
  display: flex;
  align-items: center;
}

/* list */

.ant-list-bordered {
  border-color: #e3e6ef !important;
}

.ant-list-item-meta {
  padding: 10px 24px;
  border-bottom: 1px solid #f1f2f6;
}

/* popover */

.ant-popover {
  position: fixed;
  z-index: 99999;
}

.ant-popover-inner {
  padding-top: 12px;
  box-shadow: 0 5px 20px #9299b820;
  border-radius: 0 0 6px 6px;
}

.ant-popover-inner .ant-popover-title {
  padding: 5px 10px 10px;
}

.ant-popover-inner .ant-popover-inner-content a {
  color: #5a5f7d;
}

/* Drawer */

.ant-drawer {
  z-index: 99999;
}

/* Select Dropdwon */

.ant-select-dropdown {
  box-shadow: 0 5px 20px #9299b820 !important;
  border: 1px solid #D7DBDE;
  border-radius: 0px 0px 5px 5px !important;
  border-top: 0px;
}

.ant-select-item {
  min-height: 20px !important;
  padding: 4px 12px !important;
}

.ant-select-item-group {
  color: #9299b8;
}

.ant-select-item.ant-select-item-option-grouped {
  padding-left: 25px !important;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-active {
  background: #2280F405;
}

/* .ant-select-item .ant-select-item-option .ant-select-item-option-active .ant-select-item-option-selected {
  background-color: #D9EAFD !important;
} */

/* .ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content {
  &:hover {
    background-color: #D9EAFD;
  }

  &::selection {
    background-color: #D9EAFD;
  }
} */

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
  color: var(--primary-buttons-color) !important;
  background: #2280F406;
  background-color: var(--inputs-focus-shadow-color);
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content {
  color: var(--primary-buttons-color) !important;
  font-weight: 500;
}

.ant-select-dropdown .ant-select-item .ant-select-item-option-content {
  transition: 0.3s;
  color: #5a5f7d;

  &:hover {
    color: var(--primary-buttons-color) !important;
  }
}

.ant-select-dropdown .ant-select-item {
  transition: 0.3s;
  color: #5a5f7d;

  &:hover {
    background-color: var(--inputs-focus-shadow-color);
  }
}

/* mail props dropdown */

.mail-props {
  padding: 5px 25px;
  border: 0 none;
  background: #fff;
  /* box-shadow: 0 5px 40px rgba(146, 153, 184, 0.25); */
}

.mail-props li {
  display: flex;
  margin-bottom: 12px;
}

.mail-props li span:first-child {
  margin-right: 50px;
  min-width: 40px;
}

.mail-props li span:last-child {
  color: #5a5f7d;
}

.mail-props li:last-child {
  margin-bottom: 0;
}

.mail-props li span {
  color: #9299b8;
}

/* Basic Dropdwon */

.ant-dropdown {
  box-shadow: 0 5px 30px #9299b820 !important;
}

.ant-dropdown.wide-dropdwon {
  min-width: 140px !important;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more {
  min-width: 220px !important;
  box-shadow: 0 17px 20px #9299b820;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more a {
  padding: 10px 24px;
}

.ant-dropdown-menu {
  min-width: 200px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0;
}

.ant-dropdown-menu li {
  color: #5a5f7d;
  padding: 5px 25px;
}

.ant-dropdown-menu li:hover {
  background-color: #2280F405;
}

.atbd-top-dropdwon .atbd-top-dropdwon__title {
  background: #f4f5f7;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  min-height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.atbd-top-dropdwon {
  width: 340px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav {
  height: 260px;
  overflow: hidden;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav.notification-list {
  padding: 0 10px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li {
  width: 100%;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a {
  padding: 13px 10px;
  position: relative;
  width: 100%;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li:last-child {
  margin: 0;
}

.atbd-top-dropdwon a.btn-seeAll {
  position: relative;
  width: calc(100% + 30px);
  left: -15px;
  right: -15px;
  height: calc(100% + 15px);
  bottom: -15px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: var(--primary-buttons-color);
  padding: 15px 0;
  border-radius: 0 0 6px 6px;
  background: #fff;
}

.atbd-top-dropdwon a.btn-seeAll:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 -15px 20px #9299b808;
  z-index: 1;
  content: '';
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a.btn-seeAll:hover:after {
  box-shadow: 0 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:hover {
  background: #fff;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:hover:after {
  opacity: 1;
  visibility: visible;
}

.atbd-top-dropdwon .atbd-top-dropdwon__nav li a:after {
  position: absolute;
  left: -15px;
  right: -15px;
  top: 0;
  width: calc(100% + 30px);
  height: 100%;
  box-shadow: 0 15px 50px #9299b820;
  z-index: 1;
  content: '';
  opacity: 0;
  visibility: hidden;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon {
  width: 39.2px;
  height: 32px;
  margin-right: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon.bg-primary {
  background: #2280F415;
  color: #2280F4;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon.bg-secondary {
  background: #ff69a515;
  color: #ff69a5;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-icon svg {
  width: 18px;
  height: 18px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content .notification-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.atbd-top-dropdwon .notification-text h1 {
  font-size: 14px;
  font-weight: 400;
  color: #5a5f7d;
  margin-bottom: 4px;
}

.atbd-top-dropdwon .notification-text h1 span {
  color: #2280F4;
  font-weight: 500;
  padding-left: 0;
}

.atbd-top-dropdwon .notification-text p {
  font-size: 12px;
  color: #adb4d2;
  margin-bottom: 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content img {
  max-width: 40px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption {
  margin: -4px 15px 0;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption .ant-badge-count {
  font-size: 8px;
  min-width: 16px;
  width: 16px;
  height: 16px;
  line-height: 6px;
  border-radius: 8px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption p.ant-scroll-number-only-unit.current {
  height: 15px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption h1 span {
  font-size: 12px;
  font-weight: 400;
}

.atbd-top-dropdwon .atbd-top-dropdwon__content figcaption p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.add-file-dropdown a {
  font-size: 14px;
  font-weight: 400;
}

.add-file-dropdown a svg {
  width: 14px;
}

.folder-dropdown {
  min-width: 200px !important;
}

/* Tooltip Styles */

.ant-tooltip .ant-tooltip-inner {
  min-height: 40px;
  padding: 6px 20px;
  font-size: 14px;
  color: #5a5f7d;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  border: 1px solid #f1f2f6;
  box-shadow: 0 8px 15px #9299b815;
}

.ant-tooltip .ant-tooltip-arrow {
  /* position: relative; */
}

.ant-tooltip .ant-tooltip-arrow:after {
  position: absolute;
  left: 50%;
  top: 12px;
  transform: translateX(-50%);
  width: 13px;
  height: 13px;
  background: #fff;
  content: '';
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  width: 10px;
  height: 10px;
  background: #fff;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow .ant-tooltip-arrow-content {
  border: 1px solid #f1f2f6;
  box-shadow: 0 0;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  top: -4.071068px;
}

/* Badge Styles */

.ant-badge.badge-success .ant-badge-count {
  background: #20c997;
}

.badge {
  font-size: 11px;
  font-weight: 500;
  padding: 0 6.5px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.badge.badge-primary {
  color: #FFFFFF;
  background: var(--primary-buttons-color);
}

/* Cascade Styles */

.custom-cascade-render .ant-cascader-picker {
  width: 100%;
}

.ant-cascader-picker input::placeholder {
  color: #adb4d2;
}

/* Comment Styles */

.ant-comment-actions li {
  margin-bottom: 0 !important;
}

/* Radio Vertical Button */

.ant-radio-vertical .ant-radio-wrapper {
  display: block;
}

.ant-radio-vertical .ant-radio-wrapper:not(:last-child) {
  margin-bottom: 14px;
}

/* Select Tree */

.ant-select-tree-list .ant-select-tree-node-content-wrapper:hover {
  background-color: #bae7ff !important;
}

.ant-select-tree-list .ant-select-arrow svg {
  font-size: 10px;
}

.ant-tree-select.ant-select-multiple .ant-select-selection-item {
  border: 0 none;
  border-radius: 3px;
  background: #f4f5f7;
  color: #5a5f7d;
  font-weight: 500;
}

/* Ant Switch */

.ant-switch:after {
  width: 14px !important;
  height: 14px !important;
}

.ant-switch-small:after {
  width: 10px !important;
  height: 10px !important;
}

/* Time Picker */

.ant-picker {
  min-width: 250px;
}

/* Input Affix */

.ant-input-affix-wrapper>input.ant-input {
  padding-left: 5px;
}

.ant-input-affix-wrapper .ant-input-prefix svg {
  color: #e3e6ef;
}

/* Space Item */

.ant-space-item .ant-btn span {
  font-size: 14px;
}

/* Pop confirm */

.pop-confirm .ant-btn {
  max-width: 90px;
  padding: 0px 35.5px;
  height: 44px;
}

.pop-confirm-top {
  margin-bottom: 10px;
}

.pop-confirm-bottom {
  margin-top: 10px;
}

.pop-confirm-top .ant-btn:not(:last-child),
.pop-confirm-bottom .ant-btn:not(:last-child) {
  margin-right: 10px;
}

.pop-confirm-left .ant-btn:not(:last-child),
.pop-confirm-right .ant-btn:not(:last-child) {
  margin-bottom: 10px;
}

/* Ant Upload */

.ant-upload .ant-btn {
  font-size: 14px;
  border-radius: 5px;
}

.ant-upload.ant-upload-select-picture-card {
  border-color: #e3e6ef !important;
  border-radius: 5px !important;
  background-color: #f8f9fb !important;
}

.ant-upload.ant-upload-select-picture-card .anticon {
  margin-bottom: 8px;
}

.ant-upload .anticon svg {
  color: #9299b8;
}

.ant-upload-list {
  margin-top: 10px !important;
}

.sDash_upload-basic .ant-upload.ant-upload-select {
  width: 100%;
  border: 1px solid #e3e6ef;
  border-radius: 4px;
}

.sDash_upload-basic .ant-upload.ant-upload-select .ant-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sDash_upload-basic .sDash_upload-text {
  font-size: 14px;
  color: #9299b8;
  padding-left: 15px;
}

html[dir='rtl'] .sDash_upload-basic .sDash_upload-text {
  padding-left: 0px;
  padding-right: 15px;
}

.sDash_upload-basic .sDash_upload-browse {
  font-size: 14px;
  color: #9299b8;
  display: inline-block;
  padding: 14px 23px;
  border-left: 1px solid #e3e6ef;
}

html[dir='rtl'] .sDash_upload-basic .sDash_upload-browse {
  border-left: 0 none;
  border-right: 1px solid #e3e6ef;
}

.ant-card.sDash_upload-form .ant-card-body {
  padding-bottom: 15px !important;
}

/* Ant Picker */

.ant-picker {
  border-color: #e3e6ef !important;
}

/* Ant Dropdown */

.ant-dropdown {
  box-shadow: 0 5px 20px #9299b820;
  background: #fff;
  border-radius: 6px;
  padding: 15px 0 !important;
  border: 0 none;
}

.ant-dropdown a {
  display: flex;
  align-items: center;
  padding: 8px 24px;
  font-weight: 400;
  color: #5a5f7d;
}

.ant-dropdown a i,
.ant-dropdown a svg,
.ant-dropdown a img {
  margin-right: 8px;
}

.ant-dropdown div {
  box-shadow: 0 0;
  border-radius: 5px;
}

/* Picker Under Input */

.ant-form-item-control-input .ant-picker {
  padding: 0 12px 0 0;
}

/* Leaflet COntainer */

.leaflet-container {
  z-index: 0;
}

/* Table Bordered*/

.table-bordered .ant-table-tbody>tr.ant-table-row:hover>td {
  background: #f8f9fb !important;
}

.table-bordered .ant-table-thead>tr>th {
  background: #fff;
  border-top: 1px solid #f1f2f6;
}

.table-bordered .ant-table-tbody>tr>td {
  border-color: #f1f2f6 !important;
}

.table-bordered .ant-table-thead tr th,
.table-bordered .ant-table-tbody tr td {
  padding: 16px 25px;
}

.table-bordered .ant-table-thead tr th:last-child,
.table-bordered .ant-table-tbody tr td:last-child {
  text-align: right;
}

/* Full Width Table */

.full-width-table .ant-card-body {
  padding: 0 !important;
}

.full-width-table .ant-table {
  border-radius: 10px !important;
}

.full-width-table .top-seller-table {
  min-height: 406px;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell {
  white-space: normal;
  padding: 16px 15px;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell:first-child {
  padding-left: 25px;
}

.full-width-table .top-seller-table .ant-table-content .ant-table-cell:last-child {
  padding-right: 25px;
}

.full-width-table .top-seller-table th {
  text-align: right;
  color: #272b41;
  font-weight: 500;
}

.full-width-table .top-seller-table td {
  color: #5a5f7d;
}

.full-width-table .top-seller-table .ant-table-tbody>tr>td {
  text-align: right;
}

.full-width-table .top-seller-table th:first-child,
.full-width-table .top-seller-table td:first-child {
  text-align: left !important;
}

/* Table Responsive */

.table-responsive .ant-table-content {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table-responsive .ant-table-content .ant-table-cell {
  white-space: nowrap;
}

/* Rich TextEditor  */

.RichTextEditor__root___2QXK- {
  border: 0 none !important;
}

.RichTextEditor__root___2QXK- .EditorToolbar__root___3_Aqz {
  margin: 0;
  border-color: #f1f2f6;
}

.RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button {
  padding: 0;
  border: 0 none;
  background: #fff;
  margin-right: 8px;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx .Dropdown__value___34Py9 {
  border: 0 none;
}

.RichTextEditor__root___2QXK- .Dropdown__root___3ALmx select {
  border-right-width: 0px;
}

.RichTextEditor__editor___1QqIU .DraftEditor-editorContainer {
  border: 0 none;
}

/* ChatBox Dropdwon */

.atbd-chatbox__messageControl {
  min-width: 210px;
}

.atbd-chatbox__messageControl ul li a {
  padding: 4px 24px;
}

.atbd-chatbox__emoji {
  margin: -4.48px 0;
  padding: 0 10px;
}

.atbd-chatbox__emoji ul {
  display: flex;
  align-items: center;
}

.atbd-chatbox__emoji ul li {
  display: inline-block;
}

.atbd-chatbox__emoji ul li a {
  display: block;
  font-size: 20px;
  padding: 4px 7px;
  background: #fff;
}

.atbd-chatbox__emoji ul li a:hover {
  background-color: transparent;
}

.atbd-chatbox__emoji ul li a svg {
  margin: 0;
}

.rdrMonths {
  flex-wrap: wrap;
}

.ant-space {
  flex-wrap: wrap;
}

.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-item {
  display: inline-flex;
}

.ant-menu-submenu-popup {
  z-index: 105;
}

.ant-menu-submenu-popup .ant-menu-sub {
  padding: 8px 0;
}

.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-submenu-popup .ant-menu-item {
  display: block;
}

.ant-menu-submenu-popup .ant-menu-item {
  margin-bottom: 0 !important;
}

.ant-menu-sub.ant-menu-vertical {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.ant-menu-sub.ant-menu-vertical .ant-menu-item a {
  color: #5a5f7d;
}

/* page heading */

.ant-zero-padding {
  padding: 0px !important;
}

.ant-page-header {
  /* padding: 26px 30px 26px 30px !important; */
}

.ant-page-header.header-boxed {
  padding: 26px 180px 26px 180px !important;
}

.ant-page-header-heading {
  align-items: center;
}

.ant-page-header-heading-title {
  color: #202349 !important;
  text-transform: capitalize;
}

/* Antd drag & drop */

.row-dragging {
  /* background: #fafafa; */
  /* border: 1px solid #ccc; */
  box-shadow: 0 15px 50px #9299b820;
  display: flex;
}

.row-dragging tr {
  box-shadow: 0 15px 50px #9299b820;
}

.row-dragging td {
  padding: 16px;
  color: #000;
  position: relative;
  z-index: 9999;
  opacity: 0.5;
  vertical-align: middle;
}

.row-dragging td .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #20c997;
  border-color: #20c997;
}

.row-dragging td .todos-action,
.row-dragging td .table-actions {
  display: none;
}

.row-dragging td .user-info figcaption {
  display: none;
}

.row-dragging td .feather-move,
.row-dragging td .drag_email,
.row-dragging td .drag_company,
.row-dragging td .drag_designation,
.row-dragging td .drag_join-date,
.row-dragging td .active {
  display: inline-block;
  margin-top: 10px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

/* File Export Import Modal */
.sDash_export-wrap .ant-modal {
  width: 390px !important;
}

.sDash_export-wrap .ant-modal .ant-modal-header {
  border-bottom: 0 none;
  padding: 18px 30px 0 30px;
}

.sDash_export-wrap .ant-modal .ant-modal-body {
  padding: 25px 30px 30px 30px;
}

.sDash_export-wrap .ant-modal .sDash-button-grp {
  text-align: right;
}

.sDash_export-wrap .ant-modal .sDash-button-grp {
  margin: 20px -8px -8px -8px;
}

.sDash_export-wrap .ant-modal .sDash-button-grp button {
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  height: 40px;
  padding: 0 16px;
  margin: 8px;
}

.sDash_export-wrap .ant-modal .sDash-button-grp button.ant-btn-white {
  color: #9299b8 !important;
}

.sDash_export-wrap .ant-form-item-control-input {
  min-height: 36px;
}

.sDash_export-wrap .ant-form-item-control-input input {
  font-size: 14px;
  font-weight: 400;
  padding: 6px 20px;
  border-radius: 5px;
  color: #9299b8;
}

.sDash_export-wrap .ant-select-single {
  width: 100% !important;
}

.sDash_export-wrap .ant-form .ant-form-item {
  margin-bottom: 15px;
}

.sDash_export-wrap .ant-select-single .ant-select-selector {
  padding: 0 20px;
  border-color: #e3e6ef !important;
}

.sDash_create-file .sDash-button-grp {
  text-align: right;
}

.sDash_create-file .ant-modal {
  width: 390px !important;
}

.sDash_create-file .ant-modal .ant-form-item {
  margin-bottom: 20px;
}

.sDash_create-file .ant-modal-header {
  border-bottom: 0 none;
  padding-bottom: 6px;
}

.sDash_create-file .sDash-button-grp button {
  height: 40px;
  border-radius: 5px;
  margin: 5px;
}

.sDash_create-file .ant-form-item-control-input {
  border-radius: 5px;
}

.sDash_create-file .ant-form-item-control-input input {
  border: 1px solid #e3e6ef;
}

/* Task Modal */
.sDash_task-details .ant-modal-content .ant-modal-close {
  top: 10px;
}

.sDash_task-details .ant-modal-header {
  border-bottom: 0 none;
  padding: 30px 30px 0;
}

.sDash_task-details .ant-modal {
  width: 600px !important;
}

.sDash_task-details .ant-modal-header .ant-modal-title h4 {
  font-size: 20px;
  font-weight: 500;
  color: #272b41;
  margin-bottom: 4px;
}

.sDash_task-details .ant-modal-header .ant-modal-title .sub-text {
  font-size: 14px;
  font-weight: 400;
  color: #868eae;
}

.sDash_task-details .sDash_task-details-modal .sDash_task-details__label {
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
}

.sDash_task-details .sDash_task-details-modal .sDash_task-details-modal__description textarea {
  padding: 10px 20px;
  min-height: 88px;
  width: 100%;
  border: 0 none;
  border-radius: 4px;
  background-color: #f4f5f7;
  resize: none;
}

.sDash_task-details .sDash_task-details-modal .sDash_task-details-modal__description textarea:focus {
  outline: none;
}

.sDash_task-details .sDash_task-details-modal .sDash_task-details-modal__description textarea::placeholder {
  color: #5a5f7d;
  font-size: 15px;
}

.sDash_task-details .ant-modal-body {
  padding: 14px 30px 30px 30px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block {
  margin-top: 26px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-wrap {
  position: relative;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form {
  position: absolute;
  width: 240px;
  padding: 18px;
  left: 0;
  top: 50px;
  box-shadow: 0 15px 30px #9299bb30;
  background-color: #fff;
  border: 1px solid #e3e6ef;
  border-radius: 6px;
  z-index: 222;
}

html[dir='rtl'] .sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form {
  left: auto;
  right: 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .add-checklist {
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  height: 38px;
  border: 1px solid #e3e6ef;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action {
  margin-top: 15px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action a {
  position: relative;
  top: 3px;
  display: inline-flex;
  align-items: center;
  line-height: 1;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action a svg {
  color: #9299bb;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action .btn-add {
  margin-right: 15px;
}

html[dir='rtl'] .sDash_task-details .ant-modal-body .sDash_checklist-block .addChecklist-form .addChecklist-form-action .btn-add {
  margin-left: 15px;
  margin-right: 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-block button {
  height: 38px;
  padding: 0px 18.37px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row {
  margin-top: 26px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item:not(:last-child) {
  margin-bottom: 30px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item__top button {
  padding: 0px 16.32px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist-item__title {
  font-size: 16px;
  font-weight: 500;
  color: #272b41;
  margin-bottom: 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist__progress .ant-progress-inner .ant-progress-bg {
  height: 5px !important;
  background-color: #20c997;
}

.sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist__progress .ant-progress .ant-progress-text {
  font-size: 12px;
  font-weight: 500;
  order: -1;
  margin: 0 10px 0 0;
}

html[dir='rtl'] .sDash_task-details .ant-modal-body .sDash_checklist-row .sDash_checklist__progress .ant-progress .ant-progress-text {
  margin: 0 0 0 10px;
}

.sDash_task-details .ant-modal-body .sDash_checklist-tasks {
  margin: 5px 0 5px 0;
}

.sDash_task-details .ant-modal-body .sDash_checklist-tasks .ant-checkbox-wrapper .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-color: #c6d0dc;
}

.sDash_task-details .ant-modal-body .sDash_checklist-tasks .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #20c997;
}

.sDash_task-details .ant-modal-body .sDash_checklist-tasks .ant-checkbox-wrapper .ant-checkbox-checked:after {
  border-color: #20c997;
}

.sDash_task-details .ant-modal-body .sDash_checklist-item .sDash_checklist-tasks-wrap button {
  padding: 0px 35px;
}

.sDash_task-details .ant-modal-body {
  max-height: 800px;
  overflow-y: auto;
}

#shop-wrapper-id {
  padding: 30px 20px !important;
}

#shop-main-id {
  padding: 0  !important;
  margin-top: 25px !important;
}

#my-account-wrapper-id {
  padding: 30px 20px !important;
}

#my-account-main-id {
  padding: 0  !important;
  margin-top: 25px !important;
}

 #sections-main-id {
  padding: 30px 20px !important;
  .sections-wrapper {
    margin-top: 25px;
  }
 }

@media (max-width: 699px) {
  #shop-wrapper-id {
    padding: 15px 20px !important;
  }
  #shop-main-id {
    padding: 0  !important;
    margin-top: 15px !important;
  }
  #my-account-wrapper-id {
    padding: 15px 20px !important;
  }
  #my-account-main-id {
    padding: 0  !important;
    margin-top: 15px !important;
  }
  #sections-main-id {
    padding: 15px 20px !important;
     .sections-wrapper {
      margin-top: 15px;
    }
  }
}

@media (max-width: 767px) {
  .ant-page-header {
    padding: 26px 15px 26px 15px !important;
  }
}

.page-header-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -6px -4px;
}

.page-header-actions button {
  font-size: 12px;
  font-weight: 500;
  height: 34px;
  padding: 0 12.32px;
  box-shadow: 0 3px 5px #9299b805;
  margin: 6px 4px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.page-header-actions button.ant-btn-white:focus {
  background-color: #fff !important;
}

.page-header-actions button+button {
  margin-left: 8px;
}

.page-header-actions button.ant-btn-white svg {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  color: var(--primary-buttons-color);
}

/* Layout Css */

.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #272b41 !important;
}

.ant-menu-dark .ant-menu-item a {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark .ant-menu-item a:hover {
  color: #ffffff;
}

.ant-menu-dark .ant-menu-submenu span {
  color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.05);
}

.ant-menu-inline-collapsed-tooltip a {
  color: #000 !important;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: transparent;
}

/* Chart Label */

.chart-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  color: #5a5f7d;
}

.chart-label .label-dot {
  margin-right: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.chart-label .label-dot.dot-success {
  background: #20c997;
}

.chart-label .label-dot.dot-info {
  background: var(--primary-buttons-color);
}

.chart-label .label-dot.dot-warning {
  background: #fa8b0c;
}

/* NOtification CSS */

.ant-notification {
  z-index: 99999 !important;
}

.rdrInputRange {
  padding-left: 10px !important;
}

/* Overlay Dark */
.overlay-dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #10122130;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.overlay-dark.show {
  z-index: 999;
  opacity: 1;
  visibility: visible;
}

/* Responsive CSS */

@media only screen and (max-width: 1599px) {
  .ant-page-header.header-boxed {
    padding: 26px 130px 26px 130px !important;
  }
}

@media only screen and (max-width: 1500px) {
  .full-width-table .revenue-table {
    min-height: 100%;
  }

  .pop-confirm .ant-btn {
    padding: 0 20px;
    max-width: 60px;
  }

  .pop-confirm.pop-confirm-right {
    margin-left: 300px !important;
  }

  .pop-confirm.pop-confirm-bottom,
  .pop-confirm.pop-confirm-top {
    margin-left: 80px !important;
  }
}

@media only screen and (max-width: 1399px) {
  .ant-page-header.header-boxed {
    padding: 26px 50px 26px 50px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .ant-page-header {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 991px) {
  .ant-page-header.header-boxed {
    /* padding: 26px 30px 26px 30px !important; */
  }

  .rdrMonths .rdrMonth {
    width: 100%;
    margin-bottom: 30px;
  }

  .rdrDateRangePickerWrapper.PreviewArea {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 20px;
  }

  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .ant-page-header-heading-left {
    max-width: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-page-header {
    padding: 26px 15px 26px 15px !important;
  }

  .ant-page-header-heading {
    flex-flow: column;
    align-items: center;
    justify-content: center !important;
  }

  .ant-page-header-heading-left {
    max-width: 100%;
  }

  .ant-page-header-heading .ant-page-header-heading-title {
    margin-right: 0;
    white-space: normal;
    text-align: center;
  }

  .ant-page-header-heading-extra {
    white-space: normal !important;
    margin: 12px 0 4px !important;
  }

  .ant-page-header-heading-extra .page-header-actions {
    white-space: normal !important;
    text-align: center;
  }

  .ant-card-body {
    padding: 20px !important;
  }

  .ant-card-head {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .ant-pagination .ant-pagination-item,
  .ant-pagination .ant-pagination-item.ant-pagination-prev,
  .ant-pagination .ant-pagination-item.ant-pagination-next,
  .ant-pagination .ant-pagination-jump-prev {
    margin-right: 5px;
  }

  .ant-pagination li.ant-pagination-item,
  .ant-pagination li.ant-pagination-prev,
  .ant-pagination li.ant-pagination-next {
    height: 25px;
    min-width: 25px;
    line-height: 22px;
  }

  .ant-pagination li.ant-pagination-prev .anticon,
  .ant-pagination li.ant-pagination-next .anticon {
    vertical-align: 0.15em;
  }

  .ant-table-pagination {
    float: none !important;
    text-align: center;
  }

  .ant-table-pagination li.ant-pagination-total-text {
    display: block;
    margin-bottom: 8px;
  }

  .ant-table-pagination li {
    margin-right: 8px !important;
  }

  .ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 25px !important;
  }

  .ant-pagination .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 23px !important;
  }

  .rdrDefinedRangesWrapper {
    border-right: 0 none;
  }

}

@media only screen and (max-width: 455px) {
  #signle-section-card-wrapper {
    .ant-card-head-wrapper .ant-card-head-title {
      padding: 11px 0 0px !important;
    }
  }
}

@media only screen and (min-width: 456px) and (max-width: 901px) {
  #signle-section-card-wrapper {
    .ant-card-head .ant-card-head-title {
      padding: 11px 0;
    }
  }
}

@media only screen and (max-width: 575px) {

  .btn-inc,
  .btn-dec {
    height: 30px;
    width: 30px;
  }

  .ant-page-header {
    padding: 16px 20px !important;
  }

  .ant-layout-header {
    height: auto;
  }

  .ant-card-head {
    line-height: 1;
  }

  .ant-card-head-title {
    white-space: normal !important;
  }

  .pop-confirm .ant-btn {
    max-width: 60px;
    padding: 0 20px;
  }

  .pop-confirm.pop-confirm-right {
    margin-left: 320px !important;
  }

  .pop-confirm.pop-confirm-bottom {
    margin-left: 95px !important;
  }

  /* Card Heading */
  .ant-card-head-wrapper {
    flex-flow: column;
    align-items: center;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding: 10px 0 16px !important;
    float: none;
    margin: 0;
    flex-flow: column;
  }

  .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger+.card-nav {
    margin-bottom: 8px;
  }

  .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger {
    margin: 0 !important;
  }

  .ant-card-head-wrapper .ant-card-head-title {
    padding: 20px 0 0px !important;
  }

  .card-nav ul li.active:before {
    display: none;
  }

  .ant-card-head-title>div {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .ant-card-head-title>div span {
    margin: 8px 0 0 !important;
  }
}

.bmzxig .ant-table tr th:first-child,
.bmzxig .ant-table tr td:first-child {
  padding-right: 15px;
}

/* Emprt */

.ant-empty .ant-empty-footer button {
  padding: 0px 15.58px !important;
}

/* Add Event Modal */
.addEvent-modal .ant-modal-header {
  padding: 20px 25px;
}

.addEvent-modal .ant-modal-header .ant-modal-close-x svg {
  color: #5a5f7d;
}

.addEvent-modal .ant-modal-header .ant-modal-title {
  font-size: 15px;
  font-weight: 500;
  color: #272b41;
}

.addEvent-modal .ant-modal-body {
  padding: 23px 25px;
}

/* Event Dropdown */

.event-dropdown {
  min-width: auto !important;
  max-width: 450px;
  padding: 0 !important;
  margin: 6px 0 0 !important;
  box-shadow: 0 10px 40px #9299b820 !important;
}

.event-dropdown div {
  border-radius: 8px;
}

.event-dropdown .ant-card {
  width: 100% !important;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 479px) {
  .placement-confirm {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -10px 0 -5px;
  }

  .placement-confirm .pop-confirm {
    flex: 0 0 50%;
  }

  .pop-confirm .ant-btn {
    display: block;
    padding: 0 20px;
    max-width: 80px;
    min-width: 80px;
    margin: 10px;
  }

  .pop-confirm.pop-confirm-right {
    margin-left: 0px !important;
  }

  .pop-confirm.pop-confirm-top {
    margin-left: 0px !important;
  }

  .pop-confirm.pop-confirm-bottom {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 400px) {
  .ant-select {
    width: 100% !important;
  }

  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }

  .rdrDateRangePickerWrapper {
    flex-wrap: wrap;
  }

  .atbd-top-dropdwon {
    width: 280px;
    min-width: 180px;
  }

  .atbd-top-dropdwon .atbd-top-dropdwon__title {
    min-height: 40px;
  }

  .atbd-top-dropdwon .atbd-top-dropdwon__nav li:not(:last-child) {
    margin-bottom: 10px;
  }

  .atbd-top-dropdwon .atbd-top-dropdwon__nav li a {
    padding: 10px 0px;
  }

  .atbd-top-dropdwon .atbd-top-dropdwon__content img {
    margin-right: 15px;
  }

  .atbd-top-dropdwon .atbd-top-dropdwon__content figcaption {
    margin-left: 0;
  }

  .atbd-top-dropdwon .atbd-top-dropdwon__content figcaption .atbd-top-dropdwonText {
    min-width: 155px;
  }

  .ant-drawer-content-wrapper {
    width: 260px !important;
  }

  .rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 0;
  }
}

@media only screen and (max-width: 379px) {
  .ant-card-head-wrapper .ant-card-extra .ant-radio-button-wrapper {
    height: 32px !important;
    line-height: 30px !important;
  }

  .ant-notification-notice {
    width: 275px;
  }
}

/* @media (max-width: 991px) {
  .mail-sideabr {
    box-shadow: 0 0 10px #00000020;
  }
} */

/* Invoice Print Style */
@media print {
  .invoice-area .ant-page-header {
    display: none;
  }

  .customizer-trigger {
    display: none;
  }
}

/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff !important;
} */

/* custom label */

/* card spin loader */

.ant-card-body .sd-spin div,
.ant-card-body .spin div {
  position: relative;
}

.ant-card-body .sd-spin,
.ant-card-body .spin {
  height: 200px;
}

.ant-card-body {
  position: relative;
}

.ant-card-body .sd-spin .ant-spin,
.ant-card-body .spin .ant-spin {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 200px;
}

.starActive svg {
  color: red;
}

/* vectormap zoom in / zoom out */

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 27px;
  height: 27px;
  background: none;
  color: #5a5f7d;
  border: 1px solid #f1f2f6;
  padding: 0;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: auto;
  right: 0;
  top: auto;
  background-color: #fff;
}

.jvectormap-zoomin {
  border-radius: 6px 6px 0 0;
  bottom: 36px;
}

.jvectormap-zoomout {
  border-radius: 0 0 6px 6px;
  bottom: 10px;
}

.jvectormap-tip {
  padding: 7px 12px;
  border: 0 none;
  font-size: 12px;
  background: #272b41;
}

.btn-rtl {
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  background: #4347d9;
  color: #fff;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -30px;
  z-index: 99999999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* masonry-grid start from here */

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 10px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column>div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.common-ul {
  padding: 5px 0;
  padding-left: 10px;
}

.display {
  display: none;
}

li.active>.display {
  display: block;
}

/* rtl section from here */
html[dir='rtl'] .ant-card-head-title span {
  margin-right: 15px;
}

html[dir='rtl'] span[aria-label='arrow-right'] svg,
html[dir='rtl'] span[aria-label='arrow-left'] svg {
  transform: rotateY(180deg);
}

html[dir='rtl'] .ant-menu.ant-menu-vertical {
  text-align: right !important;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-submenu-placement-rightTop {
  left: -170px !important;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-vertical-left {
  text-align: right;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
  transform: rotateY(180deg);
}

html[dir='rtl'] .ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 15px !important;
}

.wmde-markdown {
  font-family: 'Red Hat Display', sans-serif !important;
  font-weight: 500;
}

body {
  font-family: 'Red Hat Display', sans-serif !important;
  font-variant: normal !important;
  font-feature-settings: normal !important;
}

[class*="ant"] {
  font-family: 'Red Hat Display', sans-serif !important;
  font-variant: normal !important;
  font-feature-settings: normal !important;
}

.hover-text:hover {
  color: var(--primary-buttons-color);
}

.button-no-border:disabled {
  border: 0;
}

.button-rounded {
  border-radius: 10px;
}

#root {
  overflow-x: hidden;
  overscroll-behavior: none;

  @media only screen and (max-width: 1023px) {
    height: 100% !important;
  }
}

#main-row-register {
  display: flex;
  flex-flow: nowrap;
  height: 100vh;
  overflow-x: hidden;


  .ant-col-lg-12 {
    @media (max-width: 1024px) {
      display: none !important;
    }
  }

  .ant-col-lg-15 {
    @media only screen and (min-width: 992px) and (max-width: 1024px) {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
  }

  .ant-col-lg-9 {
    @media only screen and (max-width: 1024px) {
      display: none !important;
    }
  }

  .forms-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

#main-register-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0;

  @media (max-width: 1024px) {
    align-items: flex-end !important;

  }

  @media (max-width: 999px) {
    width: 100%;
    padding: 0;
  }

  @media (max-width: 425px) {
    width: 100%;
    padding: 0;
  }
}


@media only screen and (max-width: 1023px) {
  .no-yt-reward-modal {
    width: 90% !important;
  }

  .collect-reward-modal {
    width: 90% !important;
  }

}

@media only screen and (min-width: 1024px) {
  .no-yt-reward-modal {
    width: 800px !important;

    .anticon.anticon-close.ant-modal-close-icon svg {
      color: #AFB4CD !important;
    }
  }

  .collect-reward-modal {
    width: 800px !important;

    .anticon.anticon-close.ant-modal-close-icon svg {
      color: #AFB4CD !important;
    }
  }

  .yt-video-reward-modal {
    .anticon.anticon-close.ant-modal-close-icon svg {
      color: #AFB4CD !important;
    }
  }
}

@media only screen and (max-width: 575px) {
  .no-yt-reward-modal {
    width: 90% !important;
  }

  .collect-reward-modal {
    width: 90% !important;
  }
}



/* ATTACHMENTS */

/*  ATTACHMENTS OK 05-06 */
#attachment-card-id {
  width: 175px;
  height: 165px;
}

.ant-upload-list-item-success {
  position: relative; /* Ensures .ant-upload-list-item-thumbnail and spinner can be positioned absolutely within it */
}

.ant-upload-list-item-success .ant-upload-list-item-thumbnail {
  filter: blur(0.10px);
  position: relative; /* Ensures the spinner can align within the thumbnail */
}

.ant-upload-list-item-success .ant-upload-list-item-thumbnail::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px; /* Spinner size */
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #FFFFFF; /* Spinner color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 10; /* Ensure it appears above the blurred thumbnail */
}

.ant-upload-list-item-success .ant-upload-list-item-actions {
  display: none !important;
}

/* Spinning animation */
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.ant-upload-list-item-thumbnail.ant-upload-list-item-file { 
  max-width: 150px;
  max-height: 104px;
  min-width: 150px;
  min-height: 104px;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {

  display: flex !important;
  border-radius: 10px !important;
  max-height: 104px !important;
  max-width: 150px !important;
  min-height: 104px !important;
  min-width: 150px !important;
  object-fit: unset !important;

}

.ant-upload-list-item-thumbnail,
.ant-upload-list-item-thumbnail {
  background: #EFF0F3 0% 0% no-repeat padding-box;
  
  border-radius: 10px;
  display: flex;
  max-width: 150px;
  min-width: 150px;
  justify-content: center;
  align-items: center;
  max-height: 104px;
  min-height: 104px;

  svg {
    max-height: 104px !important;
    max-width: 147px !important;
    object-fit: cover !important;
  }

  img {
    border-radius: 10px;
    max-height: 104px !important;
    max-width: 150px !important;
    min-height: 104px !important;
    min-width: 150px !important;
    object-fit: cover !important;
  }
}



.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

.ant-upload-list-item-actions>a {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  display: flex;
  width: 36px !important;
  height: 36px;
  align-items: center;
  justify-content: center;
}

.ant-upload-list-item-progress {
  display: none !important;
}

.attachment-icon-div-default {
  display: flex !important;
}

.ant-upload-list-item-actions {
  gap: 5px;
  display: flex;
  top: 40% !important;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  max-height: 104px !important;
  border-radius: 10px !important;
}


.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  max-height: 104px !important;
  border-radius: 10px !important;
}

.ant-upload-list-item-name {
  text-transform: lowercase !important;
  cursor: text !important;
  text-align: start !important;
}

.file-size-text {
  position: absolute;
  display: flex;
  margin-top: -25px !important;
  margin-left: 13px;
  z-index: 30;
  font-size: 11px;
  color: #9399B5;
}

.upload-list-inline{

  .ant-upload-list-picture-card .ant-upload-list-item-file+.ant-upload-list-item-name,
  .ant-upload-list-item-name {
    display: flex;
    align-items: flex-start;
    /* height: 40px; */
    cursor: text;

    /* @media only screen and (max-width: 575px) {
      height: 25px;
    } */
  }

  .ant-upload-list-item-name,
  .ant-upload-list-item-name {
    font-size: 12px;
    font-weight: bold !important;
    white-space: normal !important;
    word-break: break-all !important;
    bottom: 12px !important;
    display: flex !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;
    text-align: start;
    color: #262626 !important;
  }

  .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card,
  .ant-upload-list-item.ant-upload-list-item-success.ant-upload-list-item-list-type-picture-card {
    border-radius: 10px !important;
  }

  .upload-icon {
    color: #B5BAD2 !important;
  }

  .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card,
  .ant-upload-list-item.ant-upload-list-item-success.ant-upload-list-item-list-type-picture-card {
    display: flex;
    /* width: 175px; 
/* height: 165px; */
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ded8d7 !important;
    border-radius: 10px;
    padding: 10px 12.5px;
  }

  .ant-upload-list-picture-card-container,
  .ant-upload-list-picture-card-container {
    width: 174px;
    margin: 0 15px 10px 0;
    height: 165px;
  }

  .ant-upload-list.ant-upload-list-picture-card,
 .ant-upload-list.ant-upload-list-picture-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .ant-upload-list-item-info,
     .ant-upload-list-item-info {
      display: flex;
      width: 100%;
    }

    .attachment-icon-div-default {
      display: flex !important;
    }

    .attachment-icon-div,
    .attachment-icon-div {
      background: #EFF0F3 0% 0% no-repeat padding-box;
      border-radius: 10px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 104px;
    }
  }
}

.upload-list-inline.hidden {
  display: none !important;
}


@media only screen and (max-width: 768px) {

  .ant-upload-list.ant-upload-list-picture-card,
 .ant-upload-list.ant-upload-list-picture-card {
    justify-content: space-between;
  }

  .ant-upload-list::before {
    display: none !important;
  }
}

@media only screen and (max-width: 575px) {

  .ant-upload-list-item-thumbnail,
   .ant-upload-list-item-thumbnail {
    svg {
      max-height: 82px !important;
      max-width: 117px !important;
      object-fit: cover !important;
    }

    img {
      max-height: 82px !important;
      max-width: 117px !important;
      min-height: 82px !important;
      min-width: 117px !important;
      object-fit: cover !important;
    }
  }

  .attachment-icon-div,
  .attachment-icon-div {
    height: 82px !important;
  }
}

@media only screen and (max-width: 468px) {

  .ant-upload-list-item-thumbnail,
   .ant-upload-list-item-thumbnail {
    min-height: 82px;
    max-height: 82px;
  }

  .ant-upload-list-item-name,
   .ant-upload-list-item-name {
    font-size: 9px !important;
    /* margin-top: 0px !important; */
    position: absolute;
    padding: 0px !important;
    cursor: text;
    margin-left: 0px !important;
    /* margin-left: -2px !important; */
  }

  .upload-icon {
    width: 37px !important;
  }

  .ant-upload-list-picture-card-container {
    width: 137px !important;
    height: 129px !important;
  }

  #attachment-card-id {
    width: 137px;
    height: 129px;
  }

  .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card,
  .ant-upload-list-item.ant-upload-list-item-success.ant-upload-list-item-list-type-picture-card {
    width: 137px;
    height: 129px;
    padding: 7px 9px !important;

  }

  .ant-upload-list-picture-card-container,
   .ant-upload-list-picture-card-container {
    width: 130px;
    height: 129px;
  }

  .attachment-icon-div,
   .attachment-icon-div {
    height: 82px !important;
  }

  .file-size-text {
    font-size: 9px !important;
    margin-top: -20px !important;
    margin-left: 10px !important;
  }
}


/* ATTACHMENTS */


.files-to-exercise-div {
  .ant-upload-list-item-name {
    text-transform: lowercase !important;
    margin-top: 4px !important;
    cursor: text !important;
    bottom: 12px !important;
    position: absolute;
  }
}


@media only screen and (max-width: 575px) {
  .ant-upload-list-item-actions {
    top: 42% !important;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    max-height: 82px !important;
  }

  .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
    max-height: 82px !important;
  }

  .ant-upload-list-item-info {
    border-radius: 10px;
  }

  .ant-upload-list-item-thumbnail,
  .ant-upload-list-item-thumbnail {
    width: 104px !important;
    border-radius: 10px !important;
    max-height: 82px !important;
    min-height: 82px !important;

    svg {
      max-height: 82px !important;
      max-width: 117px !important;
      object-fit: unset !important;
      object-fit: cover !important;
    }


    img {
      max-height: 82px !important;
      max-width: 117px !important;
      min-height: 82px !important;
      min-width: 117px !important;
      object-fit: unset !important;
      object-fit: cover !important;
    }
  }

  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: flex !important;
    border-radius: 10px !important;
    max-height: 82px !important;
    max-width: 117px !important;
    min-height: 82px !important;
    min-width: 117px !important;
    object-fit: unset !important;
  }
}



/* ATTACHMENTS WHEN UPLOADING */
.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
  margin-bottom: 5px;
}

.anticon.anticon-delete {
  svg {
    fill: #9399B5 !important;
    margin-bottom: 15px;

    @media only screen and (max-width: 468px) {
      margin-bottom: -7px;
    }
  }
}

.upload-section-desktop {
  margin-top: 10px;

  .ant-upload-list-picture-container {
    width: 174px;
    height: 165px;

    .ant-upload-list-item-name {
      line-height: 30px !important;
      flex: none !important;
      height: 22px !important;
      cursor: text;
    }
  }

  .ant-upload-list-picture-container::before {
    display: none !important;
  }

  .ant-upload-list.ant-upload-list-picture::before {
    display: none !important;
  }

  .upload-list-inline {
    margin-bottom: -10px;
  }

  .ant-upload-list.ant-upload-list-picture {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture,
  .ant-upload-list-item-success.ant-upload-list-item-list-type-picture {
    display: flex;
    height: 165px;
    max-width: 180px;
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D7DBDE !important;
    border-radius: 10px;
    padding: 10px 12.5px;


    .ant-upload-span {
      flex-direction: column;
    }

    .ant-upload-list-item-thumbnail {
      width: 100% !important;
    }

    .ant-upload-list-item-thumbnail,
    .ant-upload-list-item-thumbnail {
      width: 100%;
      justify-content: center;
      display: flex;
      border-radius: 8px;
      background: #EFF0F3 0% 0% no-repeat padding-box;
      height: 104px;
      align-items: center;

      svg {
        max-height: 104px !important;
        max-width: 150px !important;
        object-fit: cover !important;
      }

      img {
        border-radius: 10px;
        max-height: 104px !important;
        max-width: 150px !important;
        min-height: 104px !important;
        min-width: 150px !important;
        object-fit: cover !important;
      }
    }

    .ant-upload-list-item-name,
    .ant-upload-list-item-name {
      font-size: 12px !important;
      font-weight: bold;
      white-space: normal;
      word-break: break-all;
      bottom: 20px !important;
      padding: 0 0px !important;
      line-height: 20px;
      font-size: 12px !important;
      cursor: text;

      overflow: hidden !important;
      text-overflow: ellipsis !important;
      -webkit-line-clamp: 1 !important;
      -webkit-box-orient: vertical !important;
      display: -webkit-box !important;
      text-align: start;

    }
  }

  .file-size-text {
    position: absolute;
    display: flex;
    margin-top: -25px !important;
    /* margin-left: 16.5px; */
    z-index: 30;
    font-size: 11px;
    color: #9399B5;
  }

  .ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
    margin-bottom: -3px;
  }

  .anticon.anticon-delete {
    svg {
      fill: #9399B5 !important;
      margin-bottom: 15px;
    }
  }

}

@media only screen and (max-width: 575px) {
  .upload-section-desktop {
    display: none;
  }
}

@media only screen and (min-width: 500px) {
  .upload-section-mobile {
    .ant-upload-list-picture-container {
      width: 174px;
      height: 165px;
    }
  }
}

.upload-section-mobile {
  margin-top: 15px;

  .upload-list-inline {
    width: 100%;
  }

  .ant-upload.ant-upload-select.ant-upload-select-picture {
    width: 100%;
  }


  .ant-upload-list-picture-container::before {
    display: none !important;
  }

  .ant-upload-list.ant-upload-list-picture::before {
    display: none !important;
  }

  .ant-upload-list-picture-container {
    .ant-upload-list-item-name {
      flex: none !important;
      height: 22px !important;
      cursor: text;
    }
  }

  .ant-upload-list.ant-upload-list-picture {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .upload-list-inline {
    margin-bottom: -20px;
  }

  .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture,
  .ant-upload-list-item-success.ant-upload-list-item-list-type-picture {
    display: flex;
    height: 165px;
    max-width: 180px;
    justify-content: center;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D7DBDE !important;
    border-radius: 10px;
    padding: 10px 12.5px;

    .ant-upload-list {
      margin-bottom: 10px !important;
    }

    .ant-upload-span {
      flex-direction: column;
    }

    .ant-upload-list-item-info,
     .ant-upload-list-item-info {
      width: 117px !important;
    }


    .ant-upload-list-item-thumbnail,
     .ant-upload-list-item-thumbnail {
      justify-content: center;
      display: flex;
      border-radius: 8px;
      background: #EFF0F3 0% 0% no-repeat padding-box;
      height: 104px;
      align-items: center;
    }

    .ant-upload-list-picture .ant-upload-list-item-name,
     .ant-upload-list-picture .ant-upload-list-item-name {
      line-height: 0px !important;
      cursor: text;
      font-size: 9px !important;
    }
  }

  .ant-upload-list-item-name {
    font-size: 9px !important;
  }

  .file-size-text {
    position: absolute;
    display: flex;
    margin-top: -25px !important;
    z-index: 30;
    font-size: 11px;
    color: #9399B5;
  }


  .anticon.anticon-delete {
    svg {
      fill: #9399B5 !important;
      margin-bottom: 11px;
    }
  }

  .ant-upload-list-item-thumbnail,
  .ant-upload-list-item-thumbnail {
    max-width: 117px;
    min-width: 117px;
  }

}

@media only screen and (min-width: 576px) {
  .upload-section-mobile {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .upload-section-mobile {

    .upload-list-inline {
      margin-bottom: 0px !important;
    }

    .ant-upload-list {
      margin-top: 0px !important;
    }

  }
}

@media only screen and (max-width: 575px) {
  .upload-section-mobile {

    .ant-upload-list-item-thumbnail,
    .ant-upload-list-item-thumbnail {
      height: 82px !important;
    }

  }
}

@media only screen and (max-width: 468px) {
  .upload-section-mobile {

    .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture,
    .ant-upload-list-item-success.ant-upload-list-item-list-type-picture {
      width: 137px;
      height: 129px;
    }

    .ant-upload-list-item-name,
    .ant-upload-list-item-name {
      font-size: 9px !important;
      display: flex;
      align-items: flex-end;
      margin-bottom: -9px;
    }

    .file-size-text {
      font-size: 9px !important;
      margin-top: -20px !important;
    }

    .anticon.anticon-delete {
      svg {
        margin-bottom: 8px;
        height: 10px;
      }
    }
  }
}

@media only screen and (max-width: 325px) {
  .upload-section-mobile {
    .add-attachments-button-icon {
      display: none !important;
    }
  }
}

.upload-list-inline-hidden {
  display: none !important;
}

.empty-upload-section-desktop {
  margin-top: 10px;
}

.empty-upload-section-mobile {
  margin-top: 10px;
}

@media only screen and (min-width: 576px) {
  .empty-upload-section-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 575px) {
  .empty-upload-section-desktop {
    display: none !important;
  }
}

.ant-upload.ant-upload-select-picture-card {
  display: none !important;
}

.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
  background-color: rgba(255, 255, 255, 0.3);

  svg {
    fill: #fff !important;
    color: #fff !important;
    width: 12px;
    height: 16px;
    font-size: 16px;
    margin-top: 0px !important;
    margin-bottom: -2px !important;
  }
}

#basket-not-active {
  justify-content: flex-end;
}

.cancel-subcription-img {
  max-width: 150px !important;
  max-height: 150px !important;
  margin-bottom: 8px;
  margin-top: 5px;
}

.cancel-subcription-img.changing-subscription {
  margin-top: 25px;
  margin-bottom: 36px;
}

.manage-subcription-img {
  max-height: 170px !important;
  max-width: 180px !important;
  margin-bottom: 8px;
  margin-top: 5px;
}

.manage-subcription-img.one-plan {
  max-height: 170px !important;
  max-width: 255px !important;
}

.cancel-subcription-page-img {
  max-height: 142px !important;
  max-width: 178px !important;
  margin-bottom: 8px;
  margin-top: 5px;
}

.cancel-subscription-modal-title {
  color: #061A2C;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.cancel-subscription-modal-title.cancel-for-sure {
  width: 110%;
}

.cancel-subscription-modal-title.changing-subscription {
  padding: 0px 30px !important;
}

.cancel-subscription-modal-title.changing-subscription.pro {
  padding: 0px 5px !important;
}

.cancel-subscription-modal-title.unactive-account {
  color: #061A2C;
  font-size: 14px;
  font-weight: bold;
  text-align: baseline;
}

@media only screen and (min-width: 768px) {
  .to-have-access-br {
    display: none;
  }
}

.cancel-subscription-modal-description {
  color: #232529;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
}

.announcement-modal-description {
  color: #232529;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  max-width: 620px !important;

}

@media only screen and (min-width: 1025px) {
  .announcement-modal-description {
    width: 480px;
  }
}


.cancel-subscription-modal-description.changing-subscription-second-div {
  width: 330px;
}

@media only screen and (max-width: 455px) {
  .cancel-subscription-modal-title {
    font-size: 12px;
  }

  .cancel-subscription-modal-title.changing-subscription {
    padding: 0px 0px !important;
  }

  .cancel-subscription-modal-description {
    font-size: 10px;
  }

  .announcement-modal-description {
    font-size: 10px;
    width: 330px;
  }

  .cancel-subscription-modal-description.changing-subscription-second-div {
    width: 330px;
  }
}


@media only screen and (min-width: 1025px) {
  .cancel-subscription-modal {
    width: 720px !important;
  }

  .cancel-subscription-modal.cancel.announcement-modal {
    width: 700px !important;
    padding: 50px !important;

    .ant-modal-body {
      gap: 20px !important;
    }
  }
}

@media only screen and (min-width: 455px) and (max-width: 768px) {
  .ant-modal.cancel-subscription-modal.cancel {
    width: 100% !important;
    padding: 0px 20px !important;
  }

  .ant-modal.cancel-subscription-modal.thanks {
    width: 100% !important;
    padding: 0px 20px !important;
  }

  .ant-modal.cancel-subscription-modal.reasons {
    width: 100% !important;
    padding: 0px 20px !important;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(-200%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 455px) {
  .ant-modal.cancel-subscription-modal.cancel {
    .ant-modal-content {
      position: fixed !important;
      bottom: 0 !important;
      right: 0 !important;
      left: 0 !important;
      border-radius: 0px !important;
      border-top-left-radius: 14px !important;
      border-top-right-radius: 14px !important;
      animation: slideUp 1.9s ease-in-out;
    }

    .ant-modal-close {
      display: none !important;
    }
  }

  .ant-modal.cancel-subscription-modal.thanks {
    .ant-modal-close {
      display: none !important;
    }

    .ant-modal-content {
      position: fixed !important;
      bottom: 0 !important;
      right: 0 !important;
      left: 0 !important;
      border-radius: 0px !important;
      border-top-left-radius: 14px !important;
      border-top-right-radius: 14px !important;
    }
  }

  .ant-modal.cancel-subscription-modal.reasons {
    .ant-modal-content {
      position: fixed !important;
      bottom: 0 !important;
      right: 0 !important;
      left: 0 !important;
      border-radius: 0px !important;
      border-top-left-radius: 14px !important;
      border-top-right-radius: 14px !important;
    }

    .ant-modal-close {
      display: none !important;
    }
  }
}

.cancel-subscription-modal.thanks {
  .ant-modal-footer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 330px;
    border: none;
  }

  .ant-modal-body {
    max-width: 360px;
  }

  .confirm-cancel-subscription-button {
    width: 250px !important;
    font-weight: bold !important;
  }

  .confirm-cancel-subscription-button.rate-modal-button {
    width: 250px !important;
    height: 30px !important;
    font-weight: bold !important;
  }

  .confirm-cancel-subscription-button.ok-fly {
    font-size: 13px !important;
  }

  .confirm-cancel-subscription-button[disabled] {
    cursor: not-allowed !important;
  }

  .blue-text {
    color: var(--primary-buttons-color) !important;
  }

  .blue-text.link {
    color: var(--primary-buttons-color) !important;
    font-size: 14px;
    font-weight: bold !important;
  }



  .go-to-manage-subscriptions-modal-button {
    width: 250px !important;
    color: #ffffff !important;
    background-color: var(--primary-buttons-color) !important;
    border-radius: 10px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    height: 30px !important;
    font-weight: bold;
  }

  .thanks-for-subcription-img {
    max-width: 93px !important;
    max-height: 106px !important;
    margin-bottom: 8px;
    margin-top: 5px;
  }

  .thanks-for-subscription-modal-title {
    color: #061A2C;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }

  .thanks-for-subscription-modal-title.input-title {
    color: #232529;
    font-size: 11px;
    font-weight: 500;
  }

  .thanks-for-subscription-modal-description.contact-us {
    color: #707070;
    font-size: 11px;
    font-weight: 400;
    margin-top: -10px;
  }

  @media only screen and (max-width: 455px) {
    .thanks-for-subscription-modal-description.contact-us {
      width: 100% !important;
    }

    .thanks-for-subscription-modal-title {
      font-size: 12px;
    }

    .thanks-for-subscription-modal-title.mobile-center {
      width: 100% !important;
    }

    .thanks-for-subscription-modal-description {
      font-size: 10px !important;
    }

    .confirm-cancel-subscription-button.ok-fly {
      font-size: 12px !important;
    }

    .confirm-cancel-subscription-button {
      font-size: 12px !important;
    }
  }

  .thanks-for-subscription-modal-description {
    color: #232529;
    font-size: 11px;
    font-weight: 400;
    text-align: center;
  }

  .ant-modal-close-icon {
    color: #AFB4CD !important;
    width: 15px !important;
    height: 15px !important;
    stroke-width: 2px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .cancel-subscription-modal.thanks.contact-us {
    width: 90% !important;
  }
}

.cancel-subscription-modal.thanks.contact-us {
  .contact-us-title-input {
    width: 100% !important;
    height: 30px !important;
    margin-top: -5px !important;
    resize: none !important;
  }

  .contact-us-title-input::placeholder {
    padding-top: 3px !important;
  }

  .contact-us-message-input {
    width: 100% !important;
    margin-top: -5px !important;
    margin-bottom: 10px !important;
  }

  .ant-input::placeholder {
    color: #A3A8AC;
    font-size: 10px;
    font-weight: 500;
  }

  .ant-modal-content {
    align-items: flex-start !important;
    padding: 30px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
  }

  .ant-modal-footer {
    margin-top: 10px !important;
    width: 100% !important;
    border-top: 1px solid #AFB4CD !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 100%;
    padding-right: 30px;
    height: 65px !important;

    .confirm-cancel-subscription-button.contact-us {
      height: 35px;
      border-radius: 8px !important;
      width: 160px !important;
    }
  }


  .ant-modal-body {
    min-width: 100%;
    align-items: flex-start !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .confirm-cancel-subscription-button.contact-us {
    width: 330px !important;
  }
}

.cancel-subscription-modal.thanks.unactive-subscription {
  .ant-modal-close-icon {
    display: none !important;
  }
}


.cancel-subscription-modal {
  .ant-modal-content {
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    max-width: 330px;
    gap: 10px;
  }

  .ant-modal-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 330px;
    border: none;
  }

  .dont-cancel-subscription-button {
    width: 150px;
    height: 30px;
    border-radius: 10px;
    font-weight: bold !important;
    background-color: #D7DBDE !important;

    &:hover {
      background-color: var(--hover-buttons-color) !important;
      border: none !important;
      color: #FFFFFF !important;
      cursor: pointer !important;
    }
  }

  .dont-cancel-subscription-button[disabled] {
    cursor: not-allowed !important;
  }

  .confirm-cancel-subscription-button {
    width: 150px;
    height: 30px;
    border-radius: 10px;
    background-color: var(--primary-buttons-color) !important;
    font-weight: bold !important;

    &:hover {
      background-color: var(--hover-buttons-color) !important;
      border: none !important;
      color: #FFFFFF !important;
      cursor: pointer !important;
    }
  }

  .confirm-cancel-subscription-button.ok-fly {
    font-size: 13px !important;
  }

  .confirm-cancel-subscription-button[disabled] {
    cursor: not-allowed !important;
  }

  .ant-modal-close-icon {
    color: #AFB4CD !important;
    width: 15px !important;
    height: 15px !important;
    stroke-width: 2px !important;
  }
}

.confirm-change-subscription-button {
  width: 150px;
  height: 30px;
  border-radius: 10px;
  font-size: 13px;
  background-color: var(--primary-buttons-color) !important;

  &:hover {
    background-color: var(--hover-buttons-color) !important;
    border: none !important;
    color: #FFFFFF !important;
    cursor: pointer !important;
  }
}


@media only screen and (max-width: 455px) {
  .cancel-subscription-modal.thanks.contact-us {
    .ant-modal-content {
      padding-bottom: 10px !important;
    }

    .ant-modal-footer {
      border: none !important;
      justify-content: center;
      padding-right: 0px !important;
    }

    .contact-us-message-input {
      margin-bottom: 0px !important;
    }
  }

  .cancel-subscription-modal {
    .ant-modal-content {
      padding: 25px 50px;
    }
  }

  .confirm-change-subscription-button {
    font-size: 12px !important;
  }

  .dont-cancel-subscription-button {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 325px) {
  .dont-cancel-subscription-button {
    width: 100px !important;
  }

  .dont-cancel-subscription-button[disabled] {
    cursor: not-allowed !important;
  }

  .confirm-cancel-subscription-button {
    width: 100px !important;
    font-weight: bold !important;
  }

  .confirm-cancel-subscription-button[disabled] {
    cursor: not-allowed !important;
  }

  .confirm-cancel-subscription-button.ok-fly {
    font-size: 13px !important;
  }

  .confirm-change-subscription-button {
    width: 100px !important;
  }
}

.planned-subscription-row td {
  color: #878EAA !important;
}

.cancel-subscription-reason-checkbox {
  color: #061A2C !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  margin-left: 0px !important;
  width: 100% !important;

  .ant-checkbox-input {
    color: #061A2C;
    font-size: 11px;
    font-weight: 400;
    border: 1px solid #878EAA;
    border-radius: 3px;
  }


}

.ant-checkbox::after {
  /* content: ''; */
  background-image: url('../../static/img/icon/filters-check-icon.svg');
  background-repeat: no-repeat;
  background-color: var(--primary-buttons-color);
  border: none;
  background-position: center;
  background-size: 8px 8px;
  display: block;
  width: 100%;
  height: 100%;
}

.ant-checkbox-checked::after {
  /* content: ''; */
  background-image: url('../../static/img/icon/filters-check-icon.svg');
  background-repeat: no-repeat;
  background-color: var(--primary-buttons-color);
  border: none;
  background-position: center;
  background-size: 8px 8px;
  display: block;
  width: 100%;
  height: 100%;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: none !important;
    border-radius: 4px;
    border-collapse: separate;
    transition: none;
    background-image: url('../../static/img/icon/filters-check-icon.svg');
    background-repeat: no-repeat;
    background-color: var(--primary-buttons-color) !important;
    background-position: center;
    background-size: 8px 8px;

    &:after {
      display: none !important;
    }
  }
}

.ant-modal-footer {
  border: none;
}

.dont-cancel-subscription-button {
  text-shadow: none;
  box-shadow: none;
  font-size: 13px;
}

.dont-cancel-subscription-button[disabled] {
  cursor: not-allowed !important;
}

@media only screen and (max-width: 455px) {
  .confirm-cancel-subscription-button {
    font-size: 12px !important;
  }

  .cancel-subscription-reason-checkbox {
    font-size: 10px !important;
  }

  .manage-subcription-img {
    display: none;
  }
}

.filtered-exercises-col {
  .filtered-exercises-card {
    box-shadow: 3px 3px 10px #00000029 !important;
  }

  #signle-section-card-wrapper {
    margin-top: 10px;
    max-height: 184px !important;
    min-height: 184px !important;
    overflow-y: scroll;
    box-shadow: none !important;
    border-radius: 0px 0px 10px 10px !important;
    scrollbar-width: thin;

    #section-content-wrapper {
      .section-subject-list {
        margin-top: 0px;
        scrollbar-width: none;

        .list-item:last-child:not(:only-child) {
          border-radius: 0px 0px 10px 10px !important;
        }
      }
    }
  }

  .list-item {
    padding-left: 15px !important;
  }

  .no-done-exercises-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    min-height: 184px;
  }

  .no-done-exercises-text {
    color: #878EAA;
    font-weight: 600;
    font-size: 11px;
  }
}

.filtered-exercises-col.left {
  padding-right: 5px !important;
  max-width: 235px;

  .filtered-exercises-card {
    max-width: 235px;
    border-radius: 10px !important;
  }
}

.filtered-exercises-col.right {
  padding-left: 5px !important;
  max-width: 235px;

  .filtered-exercises-card {
    max-width: 235px;
    border-radius: 10px !important;
  }
}

@media only screen and (max-width: 950px) {
  .filtered-exercises-col.right {
    max-width: 100% !important;

    .filtered-exercises-card {
      margin-top: 0px !important;
      max-width: 100% !important;
    }
  }

  .filtered-exercises-col.left {
    .filtered-exercises-card {
      max-width: 100% !important;
    }
  }

  .filtered-exercises-col.left {
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 1720px) {
  .filtered-exercises-col.left {
    max-width: 100%;

    .filtered-exercises-card {
      max-width: 100%;
    }
  }

  .filtered-exercises-col.right {
    max-width: 100%;

    .filtered-exercises-card {
      max-width: 100%;
    }
  }
}

#unactive-subscription-modal-container {
  .ant-row.modal-row {
    @media only screen and (max-width: 768px) {
      max-width: 100% !important;
      max-height: 100% !important;
      min-width: 92% !important;
    }

    .cancel-subscription-modal.thanks.unactive-subscription {
      padding: 20px 10px !important;
    }
  }
}

.confirm-cancel-subscription-button.right {
  margin-left: 10px !important;
}

.ant-modal.cancel-subscription-modal.cancel {
  .ant-modal-footer {
    justify-content: center;
  }
}

.ant-modal.cancel-subscription-modal.cancel.rate-modal {
  .ant-modal-body {
    max-width: 400px !important;
  }

  .confirm-cancel-subscription-button.rate-modal-button {
    width: 250px !important;
    height: 30px !important;
    font-weight: bold !important;
    gap: 7px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 30px;
  }

  .cancel-subscription-modal-description.rate-modal-description {
    margin-top: 10px;
  }

  .ant-modal-close-x {
    &:hover {
      background-color: #F0F7FF !important;
      border-radius: 10px;
    }
  }
}

.ant-modal.cancel-subscription-modal.reasons {
  .confirm-cancel-subscription-button {
    margin-left: 10px !important;
  }

  .ant-modal-footer {
    justify-content: center;
  }
}

.ant-empty-description {
  color: #A3A8AC !important;
  font-weight: 400;
}

.ant-empty.ant-empty-normal {
  margin: 0px 0px;
}

.close-modal-x-icon {
  g {
    circle {
      display: none;
    }
  }
}

@media only screen and (min-width: 466px) and (max-width: 767px) {
  .my-account-header {
    padding-left: 35px !important;
  }


  #my-account-wrapper-id {
    .ant-page-header-heading-title {
      padding-left: 35px !important;
    }
  }
}

@media (max-width: 455px) {
  #shop-wrapper-id {
    .ant-page-header-heading-title {
      padding-left: 20px !important;
    }
  }

  #my-account-wrapper-id {
    .ant-page-header-heading-title {
      padding-left: 20px !important;
    }
  }
}

.ant-table-pagination-right {
  .anticon.anticon-left {
    svg {
      color: #878EAA;
      fill: #878EAA;
    }
  }

  .anticon.anticon-right {
    svg {
      color: #878EAA;
      fill: #878EAA;
    }
  }
}

.register-next-and-prev-btn {
  padding: 0px !important;
  border-radius: 7px !important;
}

@media only screen and (max-width: 455px) {
  #my-account-wrapper-id {
    padding-bottom: 30px !important;
  }

  #shop-wrapper-id {
    padding-bottom: 30px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .register-next-and-prev-btn svg {
    margin-right: 0px !important
  }

  .register-prev-btn {
    gap: 5px !important;
  }
}

#register-prev-btn {
  border-radius: var(--register-buttons-border-radius) !important;
}

#register-prev-btn:hover {
  background-color: var(--hover-buttons-color) !important;
  transition: none !important;
  border-radius: var(--register-buttons-border-radius) !important;
}

#register-prev-btn:focus {
  /* background-color: var(--hover-buttons-color) !important; */
  border-radius: var(--register-buttons-border-radius) !important;
}

#account-register {
  .ant-select-arrow {
    top: 43% !important;
    right: 5% !important;
  }
}

#disabled-subscription-tooltip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  color: #FFFFFF !important;
  font-weight: 400 !important;
  font-size: 8px !important;
  border: none !important;
  border-radius: 10px !important;
  width: 100% !important;
}

#active-subscription-tooltip {
  display: none !important;
}

.ant-tooltip-arrow-content::after {
  display: none !important;
}

.ant-tooltip-arrow::after {
  display: none !important;
}

.bold-no-sub-header-text {
  font-weight: bold;
  font-size: 12px;
  font-family: 'Roboto' !important;
}

.no-subscription-header-text {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  font-family: 'Roboto' !important;

  @media only screen and (max-width: 500px) {
    font-size: 9px;
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 3em;
  }

}

.rate-text {
  font-weight: 300;
  line-height: 15px;
  font-size: 12px;
  color: #232529;
}

.rate-text-bold {
  font-weight: bold;
  line-height: 15px;
  font-size: 12px;
  color: #232529;
}

#dicount-input::placeholder {
  color: red !important;
  font-size: 11px !important;
  font-weight: bold !important;
}

.ant-input.discount-input::placeholder {
  color: #A3A8AC !important;
  font-size: 11px !important;
}

#discount-input-content {
  background-color: #DDEBFD !important;
  border: 1px solid var(--primary-buttons-color) !important;
  box-shadow: none !important;
  font-size: 11px;
  font-weight: bold;
  color: #232529;
}

#order-wizard-inputs:focus {
  border: 1px solid var(--primary-buttons-color) !important;
  box-shadow: none !important;
}

#order-wizard-inputs::placeholder {
  color: #B5BAD2 !important;
  font-size: 12px !important;
}

#order-wizard-inputs {
  color: #5D5F79 !important;
  font-size: 12px !important;
}

#order-wizard-inputs-blocked {
  color: #5D5F79 !important;
  font-size: 12px !important;
  background-color: #F8F9FB !important;
  padding-left: 32px !important;
}

#blocked-mail-icon {
  position: absolute;
  left: 13px;
  top: 12px;
}

#invoice-checkbox-text {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #232529 !important;
  line-height: 16px !important;

  .link-policy {
    font-weight: bolder !important;
    color: #232529 !important;
    text-decoration: underline !important;
    line-height: 16px !important;
  }
}

.payment-summary-table-wrapper {
  line-height: 16px !important;

  .ant-checkbox-wrapper {
    line-height: 16px !important;
  }
}

#invoice-checkbox {
  max-width: 15px !important;
  max-height: 15px !important;
}

#subscriptons-menu-cart-mobile-section {
  border-radius: 0px 0px 10px 10px !important;
}

@media only screen and (max-width: 455px) {
  #subscriptons-menu-cart-mobile-section>ul {
    height: 100%
  }

  #links-cart-nav {
    flex-direction: row;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (max-width: 991px) {
  #mobile-cart-cover-section {
    margin-top: 0px !important;
  }
}

#links-cart-nav {
  .navlink-style.normal {
    text-decoration: none !important;
    font-size: 12px !important;
    color: #232529 !important;
    font-weight: 400 !important;
  }

  .navlink-style.blocked.cart {
    text-decoration: none !important;
    font-size: 12px !important;
    color: #878EAA !important;
    font-weight: 400 !important;
  }

  .navlink-style.blocked.cart:hover {
    cursor: not-allowed !important;
  }

  .navlink-style.selected {
    font-size: 12px !important;
    font-weight: bolder !important;
    color: var(--primary-buttons-color) !important;
  }
}


#signle-section-card-wrapper {
  .ant-collapse {
    border-radius: 10px !important;
  }

  .collapse-wrapper {
    border-radius: 10px !important;
  }

  .ant-collapse .ant-collapse-icon-position-left .collapse-wrapper .open {
    border-radius: 10px !important;
  }
}

#single-section-card-wrapper-id {
  border-radius: 10px !important;
}

@media only screen and (max-width: 455px) {
  .remaining-elements {
    .ant-card-head-title {
      padding-top: 12px !important;
    }
  }
}

#last-step-div {
  .ant-btn-primary {
    border: none !important;
    background-color: var(--primary-buttons-color) !important;

    &:hover {
      background-color: var(--hover-buttons-color) !important;
    }
  }
}

#sign-in-password-row {
  .ant-input-affix-wrapper {
    &:focus {
      box-shadow: 0 0 0 5px var(--inputs-focus-shadow-color) !important;
    }

    &:focus-visible {
      box-shadow: 0 0 0 5px var(--inputs-focus-shadow-color) !important;
    }

    &:focus-within {
      box-shadow: 0 0 0 5px var(--inputs-focus-shadow-color) !important;
    }

    &::selection {
      box-shadow: 0 0 0 5px var(--inputs-focus-shadow-color) !important;
    }

    &:active {
      box-shadow: 0 0 0 5px var(--inputs-focus-shadow-color) !important;
    }

    &:hover {
      border-color: var(--primary-buttons-color) !important;
      box-shadow: 0 0 0 5px var(--inputs-focus-shadow-color) !important;
    }
  }

  .ant-input-affix-wrapper:focus {
    box-shadow: 0 0 0 5px var(--inputs-focus-shadow-color) !important;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 5px var(--inputs-focus-shadow-color) !important;
  }
}



#account {
  .ant-input {
    &:hover {
      border-color: var(--primary-buttons-color) !important;
    }

    &:active {
      border-color: var(--primary-buttons-color) !important;
    }

    &:focus {
      box-shadow: 0 0 0 2px var(--inputs-focus-shadow-color) !important;
    }
  }
}

.ant-input:focus {
  border-color: var(--primary-buttons-color) !important;
}

.ant-input-focused {
  border-color: var(--primary-buttons-color) !important;
}

#reserve-call-button-id {
  background-color: var(--primary-buttons-color) !important;
  border: none !important;
}

#reserve-call-button-id:hover {
  background-color: var(--hover-buttons-color) !important;
  border: none !important;
}

#add-ticket-btn-id {
  background-color: var(--primary-buttons-color) !important;
}

#add-ticket-btn-id:hover {
  background-color: var(--hover-buttons-color) !important;
}

#ticket-compose-add-button-id {
  background-color: var(--primary-buttons-color) !important;
  width: 180px !important;
  height: 30px !important;
  font-size: 13px !important;
  color: #FFFFFF !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  padding-right: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
}

#ticket-compose-add-button-id:hover {
  background-color: var(--hover-buttons-color) !important;
}

#next-register-button {
  background-color: var(--primary-buttons-color) !important;
  border-radius: var(--register-buttons-border-radius) !important;
  border-color: var(--primary-buttons-color) !important;
}

#next-register-button:hover {
  background-color: var(--hover-buttons-color) !important;
}

#next-register-button:disabled {
  background-color: var(--disabled-buttons-color) !important;

  &:hover {
    background-color: var(--hover-buttons-color) !important;
  }
}

#next-register-button:disabled:hover {
  background-color: var(--hover-buttons-color) !important;
}

#done-btn-text {
  background-color: var(--primary-buttons-color) !important;
  border-radius: var(--register-buttons-border-radius) !important;
}

#done-btn-text {
  background-color: var(--hover-buttons-color) !important;
}

#done-btn-text:disabled {
  background-color: var(--disabled-buttons-color) !important;

  &:hover {
    background-color: var(--hover-buttons-color) !important;
  }
}

#done-btn-text:disabled:hover {
  background-color: var(--hover-buttons-color) !important;
}

.lecture-checkbox-div>input:checked {
  background-color: var(--primary-buttons-color) !important;
}

#mistake-in-exercise-button-id:hover {
  color: var(--hover-report-error-buttons) !important;
}

#mistake-in-exercise-button-id:active {
  color: #5D5F79 !important;
}

#mistake-in-exercise-button-id::selection {
  color: #5D5F79 !important;
}

#mistake-in-exercise-button-id:focus {
  color: #5D5F79 !important;
}

.w-md-editor-toolbar li>button:hover {
  color: var(--editor-buttons-hover) !important;
}

.w-md-editor-toolbar li.active>button {
  color: var(--editor-buttons-hover) !important;
}

.ant-pagination-item>a {
  background-color: #FFFFFF !important;
  color: #878EAA !important;
  /* border: none !important; */
  border-radius: 4px !important;
  /* border: 0.5px solid #c9c9d0 !important; */
}

.ant-pagination-item {
  border: none !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.ant-pagination-item>a {
  font-size: 13px !important;
  height: 100% !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-pagination .ant-pagination-item-active {
  border: none !important;
}

.ant-pagination-item-active>a {
  background-color: var(--primary-buttons-color) !important;
  border: none !important;
  border-radius: 4px !important;
  color: #FFFFFF !important;
  font-size: 13px !important;
}

#spinner-id {
  color: var(--primary-buttons-color) !important;
}

#task-details-send-button-id {
  background-color: var(--primary-buttons-color) !important;
  border-radius: 10px !important;
  height: 30px !important;
  width: 180px !important;
  font-size: 13px !important;
  color: #FFFFFF !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
}

#task-details-send-button-id:hover {
  background-color: var(--hover-buttons-color) !important;
}

#refferal-code-copy-icon-id {
  color: var(--primary-buttons-color) !important;
}

#ask-darek-button-id {
  background-color: var(--primary-buttons-color) !important;
  color: #FFFFFF !important;
  border: none !important;
}

#ask-darek-button-id:hover {
  background-color: var(--hover-buttons-color) !important;
  color: #FFFFFF !important;
  border: none !important;
}

#send-error-message-btn-id {
  background-color: var(--primary-buttons-color) !important;
  border: none !important;
}

.ant-modal-footer {
  .ant-btn-primary {
    background-color: var(--primary-buttons-color) !important;
    border: none !important;
  }

  .ant-btn-primary:hover {
    background-color: var(--hover-buttons-color) !important;
    border: none !important;
  }

  .ant-btn-primary:disabled {
    background-color: var(--disabled-buttons-color) !important;
    border: none !important;
  }
}

#send-error-message-btn-id:hover {
  background-color: var(--hover-buttons-color) !important;
  border: none !important;
}

.send-error-message-btn-id {
  background-color: var(--primary-buttons-color) !important;
  border: none !important;
}

.send-error-message-btn-id:hover {
  background-color: var(--hover-buttons-color) !important;
  border: none !important;
}

#text-area-send-error-message:focus {
  border-color: var(--primary-buttons-color) !important;
  box-shadow: 0 0 0 2px var(--inputs-focus-shadow-color) !important;
}

#text-area-send-error-message:active {
  border-color: var(--primary-buttons-color) !important;
  box-shadow: 0 0 0 2px var(--inputs-focus-shadow-color) !important;
}

#text-area-send-error-message:hover {
  border-color: var(--primary-buttons-color) !important;
}

@media only screen and (max-width: 455px) {
  #rewards-page-row-wrapper-id {
    padding-bottom: 20px;
  }

  #sections-main-id {
    padding-bottom: 30px !important;
  }
}

.ant-spin-dot-item {
  background-color: var(--primary-buttons-color) !important;
}

.negative-points-avatar-div {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EC8B4A;
  margin-right: 10px;

  .negative-points-avatar {
    width: 15px !important;
    height: 15px !important;
    border-radius: 0px !important;
  }
}

@media only screen and (max-width: 455px) {
  #negative-points-avatar {
    .avatar-wrapper-div-comments-status-badge {
      padding-top: 20px !important;
      margin-left: 20px !important;
    }
  }
}


#ticket-compose-editor-id {
  border: 1px solid #D7DBDE !important;
  border-radius: 10px !important;
  z-index: 11 !important;
  position: relative !important;
  overflow: clip !important;

  .w-md-editor-text-input {
    border: none !important;
    /* padding-left: 17px !important; */
  }
}

@media only screen and (min-width: 555px) {
  #ticket-compose-editor-id>div:nth-child(2) {
    border-radius: 10px !important;
    top: -15px !important;
    padding-top: 15px !important;
  }
}

@media only screen and (max-width: 1500px) {
  #task-detail-view-editor-wrapper-admin-id {
    .w-md-editor-toolbar {
      height: 55px !important;
    }
  }
}

#ticket-compose-editor-wrapper-id {
  .w-md-editor-bar>svg {
    color: #D7DBDE !important;
    margin-left: -2px !important;
  }
}

@media only screen and (max-width: 555px) {
  #ticket-compose-editor-wrapper-id {
    .w-md-editor-toolbar {
      height: 49px !important;
    }
  }

  #task-detail-view-editor-wrapper-id {
    .w-md-editor-toolbar {
      height: 49px !important;
    }
  }

  #task-detail-view-editor-wrapper-admin-id {
    .w-md-editor-toolbar {
      height: 49px !important;
    }
  }

}

@media only screen and (max-width: 355px) {
  #ticket-compose-editor-wrapper-id {
    .w-md-editor-toolbar {
      height: 75px !important;
    }
  }

  #task-detail-view-editor-wrapper-id {
    .w-md-editor-toolbar {
      height: 75px !important;
    }
  }

  #task-detail-view-editor-wrapper-admin-id {
    .w-md-editor-toolbar {
      height: 75px !important;
    }
  }
}

@media only screen and (min-width: 567px) {
  #ticket-compose-editor-wrapper-id {
    .wmde-markdown-color {
      min-height: 1px !important;
      max-height: 100% !important;
      overflow: scroll !important;
    }
  }

  #ticket-compose-editor-id {
    .wmde-markdown-color {
      max-height: 100% !important;
      overflow: scroll !important;
    }
  }
}

@media only screen and (min-width: 2000px) {
  #ticket-compose-editor-wrapper-id {
    .wmde-markdown-color {
      min-height: 1px !important;
      max-height: 100% !important;
      overflow: scroll !important;
    }
  }
}

@media only screen and (max-width: 455px) {
  .table-responsive.all-tickets {
    .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
      padding: 0px !important;
      text-align: left !important;
    }

    .email-time {
      position: absolute;
      bottom: 20px;
    }
  }

  #all-tickets-title-first-column {
    max-width: 125px;
    word-break: break-all;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }


  #ticket-compose-editor-id {
    .w-md-editor-content {
      height: 151px !important;
    }

    .w-md-editor-toolbar {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }
  }

  #editor-wrapper-mobile {
    .w-md-editor-content {
      height: 151px !important;
    }
  }

  #open-ticket-editor-id {
    height: 230px !important;

    .w-md-editor-content {
      height: 151px !important;
    }
  }
}

@media only screen and (min-width: 555px) {
  #open-ticket-editor-id>div:nth-child(2) {
    border-radius: 10px !important;
    top: -15px;
    padding-top: 15px;
  }
}


#open-ticket-editor-id {
  box-shadow: none !important;
  border: 1px solid #D7DBDE !important;
  border-radius: 10px !important;
  border: 1px solid #D7DBDE !important;
  border-radius: 10px !important;
  z-index: 11 !important;
  position: relative !important;
  overflow: clip !important;

  .w-md-editor-text-input {
    border: none !important;
  }

  .w-md-editor-text {
    padding-top: 15px !important;
    padding-left: 17px !important;
  }

  .w-md-editor-text-input::placeholder {
    font-size: 13px !important;
    font-weight: 400 !important;
    font-style: italic !important;
    color: #AFB4CD !important;

    @media only screen and (max-width: 455px) {
      font-size: 10px !important;
    }
  }

  .w-md-editor-toolbar {
    border-radius: 10px 10px 0px 0px !important;
    min-height: 40px !important;
    flex-wrap: wrap;
    height: unset !important;
    display: flex;
    align-items: center;

    .w-md-editor-bar {
      margin-top: -30px !important;
      color: #D7DBDE !important;
      background-color: #D7DBDE !important;
      fill: #D7DBDE !important;
      right: 3px !important;
    }

    ul {
      display: flex !important;
      align-items: center !important;
      flex-wrap: wrap !important;
    }

    ul:first-child {
      padding-left: 12px !important;
    }

    ul:last-child {
      padding-right: 12px !important;
    }

    @media only screen and (max-width: 786px) {
      ul:first-child {
        margin-top: 5px !important;
      }

      ul:last-child {
        padding-right: 0px !important;
        padding-left: 12px !important;
        margin-bottom: 5px !important;
      }
    }

    .pythoncode-button {
      height: 100% !important;
      align-items: center !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      height: 20px !important;
      width: 20px !important;
      border-radius: 3px !important;
      /* margin: 0px !important; */
      padding: 0px !important;

      svg {
        min-height: 15px !important;
        min-width: 15px !important;
        max-width: 15px !important;
      }
    }

    .pythoncode-button:hover>svg {

      g {
        fill: var(--editor-buttons-hover) !important;
      }
    }

    .cpp-code-button {
      height: 100% !important;
      align-items: center !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      height: 20px !important;
      width: 20px !important;
      border-radius: 3px !important;
      /* margin: 0px !important; */
      padding: 0px !important;

      svg {
        min-height: 20px !important;
        min-width: 20px !important;
        max-width: 20px !important;
      }
    }

    .cpp-code-button:hover>svg {
      g {
        fill: var(--editor-buttons-hover) !important;
      }
    }
  }
}

@media only screen and (max-width: 335px) {
  .table-responsive.all-tickets {
    .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
      span {
        position: absolute;
        left: 0;
      }
    }
  }

  #all-tickets-title-first-column {
    max-width: 115px;
  }
}


#ticket-comment-id {
  .wmde-markdown.wmde-markdown-color>ul {
    font-size: 15px !important;
    color: #5A5F7D !important;
  }

  .wmde-markdown code {
    white-space: break-spaces !important;
  }
}

.contains-task-list {
  .task-list-item {
    list-style: none !important;
  }
}

.black-text.tester-modal {
  color: #232529;
  font-weight: bold !important;
}

#tester-modal {
  position: relative !important;
}

#unactive-subscription-modal-container {
  & .ant-row.modal-row {
    .cancel-subscription-modal.thanks.unactive-subscription.announcement-modal {
      max-width: 1080px !important;
      text-align: center !important;
      padding: 50px !important;
      margin-top: -30px;
    }
  }
}


@media only screen and (max-width: 768px) {

  #unactive-subscription-modal-container {
    & .ant-row.modal-row {
      .cancel-subscription-modal.thanks.unactive-subscription.announcement-modal {
        margin-left: 20px !important;
        margin-right: 20px !important;
        height: 70% !important;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  #unactive-subscription-modal-container {
    & .ant-row.modal-row {
      .cancel-subscription-modal.thanks.unactive-subscription.announcement-modal {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
        gap: 5px !important;
      }
    }
  }
}

#annoucement-button-id {
  color: #AFB4CD !important;
}

@media only screen and (max-width: 768px) {
  #annoucement-button-id {
    right: 20px !important;
  }
}

@media only screen and (max-width: 390px) {
  #annoucement-button-id {
    font-size: 10px !important;
    top: -3px !important;
    width: 10px !important;
    height: 10px !important;
  }
}

.ant-input-affix-wrapper:focus {
  box-shadow: 0 0 0 2px var(--inputs-focus-shadow-color) !important;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  box-shadow: 0 0 0 2px var(--inputs-focus-shadow-color) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

input::selection {
  background-color: var(--primary-buttons-color) !important;
  background: var(--primary-buttons-color) !important;
}

@media only screen and (min-width: 1025px) {
  .cancel-subscription-modal.cancel.announcement {
    width: 700px !important;

    .ant-modal-content {
      padding: 50px !important;
    }

    .ant-modal-body {
      gap: 20px !important;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .cancel-subscription-modal.cancel.announcement {
    top: calc(33% - 82px) !important;
  }
}

@media only screen and (min-width: 1400px) {
  .cancel-subscription-modal.cancel.announcement {
    top: calc(33% - 82px) !important;
  }
}

@media only screen and (min-width: 1700px) {
  .cancel-subscription-modal.cancel.announcement {
    top: calc(40% - 82px) !important;
  }
}

@media only screen and (min-width: 2100px) {
  .cancel-subscription-modal.cancel.announcement {
    top: calc(45% - 82px) !important;
  }
}

#task-detail-view-editor-wrapper-id {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 11px !important;

  .wmde-markdown code {
    white-space: break-spaces !important;
  }

  .wmde-markdown.wmde-markdown-color {
    line-height: 17px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #262626 !important;
    word-wrap: break-word !important;
    padding-left: 15px !important;
    padding-top: 3px !important;

    a {
      overflow-wrap: anywhere;
    }
  }

  .wmde-markdown.wmde-markdown-color ul,
  .wmde-markdown.wmde-markdown-color ul>li,
  .wmde-markdown.wmde-markdown-color blockquote>p,
  .wmde-markdown.wmde-markdown-color code,
  .wmde-markdown.wmde-markdown-color ol,
  .wmde-markdown.wmde-markdown-color table>tbody {
    font-size: 13px !important;
    color: #262626 !important;
    font-weight: 400 !important;
    font-family: 'Red Hat Display', sans-serif !important;
  }

  .preview-text {
    line-height: 17px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #262626 !important;
    padding-left: 15px !important;
  }
}


#crm-button-id {
  background-color: var(--primary-buttons-color) !important;
  color: #FFFFFF !important;
  border: none !important;
}

#crm-button-id:hover {
  background-color: var(--hover-buttons-color) !important;
  color: #FFFFFF !important;
  border: none !important;
}

#crm-button-id:focus {
  background-color: var(--hover-buttons-color) !important;
  color: #FFFFFF !important;
  border: none !important;
}

#tickets-tickets-table-wrapper-id {
  width: 100%;

  .custom-column-style {
    padding: 0px 16px !important;
    vertical-align: middle;
  }

  .title-tickets-class {
    vertical-align: middle;
  }

  .ant-table-cell {
    vertical-align: middle;
  }

  .ant-card-body {
    min-height: 1px !important;
  }

  .ant-table-container {
    padding-bottom: 0px !important;
    border: none !important;
    border-bottom: none !important;
  }

  .ant-table-row.ant-table-row-level-0.no-pointer {
    cursor: pointer !important;

    &:hover {
      background-color: #FFFFFF !important;
      background: #FFFFFF !important;
    }

    &:focus {
      background-color: #FFFFFF !important;
      background: #FFFFFF !important;
    }
  }

  .no-tickets-div-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
  }

  .no-tickets-empty-table-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
    width: 100%;
    border: 0.5px solid #B5BAD2;
  }

  .no-tickets-header-div {
    border-bottom: 1px solid #B5BAD2;
    background-color: #F8F9FB;
    border-radius: 10px 10px 0px 0px;
    padding-left: 25px;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    color: #232529;
    font-weight: bold;
    width: 100%;
    align-items: center;
  }

  .no-done-exercises-div {
    min-height: 152px;
    align-items: center;
    justify-content: center;
    display: flex
  }

  .no-tickets-buttons-row-div-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
  }

  .no-tickets-buttons-content-div {
    font-size: 9px;
    font-weight: 500;
    color: #232529;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
    margin-top: 26px;
  }

  .no-tickets-pagination-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    border: 0.5px solid #B5BAD2;
  }

  .no-tickets-pagination-button.blue {
    border: 0.5px solid #B5BAD2;
    font-size: 13px;
    color: #FFFFFF;
    background: #2280F4;
  }

  .email-header>h1 {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #232529 !important;
    font-weight: bold !important;

    &:hover {
      color: var(--primary-buttons-color) !important;
      text-decoration: underline !important;
    }
  }

  #all-tickets-title-first-column {
    margin-bottom: 0px !important;
  }

  #all-tickets-title-first-column>a {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #232529 !important;
    font-weight: bold !important;
    /* margin-left: 9px !important; */

    &:hover {
      color: var(--primary-buttons-color) !important;
      text-decoration: underline !important;
    }
  }

  #all-tickets-title-first-column-admin>a {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #232529 !important;
    font-weight: 500 !important;
    /* margin-left: 9px !important; */

    &:hover {
      color: var(--primary-buttons-color) !important;
      text-decoration: underline !important;
    }
  }

  .email-time {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #232529 !important;
    font-weight: bold !important;
    text-align: left !important;

    &:hover {
      color: var(--primary-buttons-color) !important;
      text-decoration: underline !important;
    }
  }

  .email-header.status>h1 {
    margin-bottom: 0px !important;
  }

  /* 
  .email-header.status {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
  } */

  /* 
  .email-time-div {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
  } */

  .tickets-status-dott {
    min-width: 6px;
    min-height: 6px;
    max-width: 6px;
    max-height: 6px;
    border-radius: 100%;
    align-self: center;
    /* margin-top: -5px; */
  }

  .ant-table-cell:nth-child(3) {
    text-align: left !important;
  }
}

.rateText {
  cursor: pointer !important;
}

#rateTicketAnswerTextWrapper {
  padding-left: 10px !important;
  gap: 3px !important;
}

@media only screen and (max-width: 455px) {
  .rateText {
    font-size: 10px !important;
  }

  #openRateModalIcon {
    max-width: 10px !important;
    max-height: 10px !important;
    size: 10px !important;
  }

}


.rateText:hover {
  text-decoration: underline !important;
}

#ticket-compose-editor-id {
  box-shadow: none !important;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul:first-child {
    padding-left: 12px !important;
  }

  ul:last-child {
    padding-right: 12px !important;
  }

  @media only screen and (max-width: 786px) {
    ul:first-child {
      /* padding-left: 0px !important; */
    }

    ul:last-child {
      padding-right: 0px !important;
      padding-left: 12px !important;
    }
  }

  @media only screen and (max-width: 455px) {
    ul:first-child {
      padding-left: 5px !important;
    }

    ul:last-child {
      padding-left: 5px !important;
    }
  }

  .w-md-editor-toolbar {
    border-bottom: 1px solid #D7DBDE !important;
    border-radius: 10px 10px 0px 0px !important;
    min-height: 40px !important;
    flex-wrap: wrap;
    height: unset !important;
    z-index: 10;
    position: relative;
    /* border-radius: 10px; */

    .pythoncode-button {
      height: 100%;
      align-items: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      /* margin: 0px; */
      padding: 0px;

      svg {
        min-height: 15px !important;
        min-width: 15px !important;
        max-width: 15px !important;
      }
    }

    .pythoncode-button:hover>svg {

      g {
        fill: var(--editor-buttons-hover) !important;
      }
    }

    .pythoncode-button svg g {
      fill: #586069 !important;
    }

    .cpp-code-button svg g {
      fill: #586069 !important;
    }
  }

  #task-detail-view-col-student-id {
    .pythoncode-button svg g {
      fill: #586069 !important;
    }

    .cpp-code-button svg g {
      fill: #586069 !important;
    }
  }



  @media only screen and (max-width: 390px) {
    .w-md-editor-toolbar>ul {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  .cpp-button {
    height: 100%;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    /* margin: 0px; */
    padding: 0px;

    svg {
      min-height: 20px !important;
      min-width: 20px !important;
      max-width: 20px !important;
    }
  }

  .cpp-button:hover>svg {
    g {
      fill: var(--editor-buttons-hover) !important;
    }
  }

  .w-md-editor-text-input {
    /* border: 1px solid #D7DBDE !important; */
    border-top: none !important;
    border-radius: 0px 0px 10px 10px !important;
    border: none !important;
  }

  .w-md-editor-text-input::placeholder {
    font-style: italic !important;
    font-size: 13px !important;
    color: #AFB4CD !important;
    font-weight: 400 !important;

    @media only screen and (max-width: 455px) {
      font-size: 12px !important;
    }
  }

  .w-md-editor-content {
    margin-top: -7px !important;
  }

  .w-md-editor-input {
    /* padding-top: 7px; */
  }

  .w-md-editor-text {
    padding-top: 15px !important;
    /* padding-left: 17px !important; */
  }

  @media only screen and (max-width: 455px) {
    .w-md-editor-text-input {
      /* padding-left: 10px !important; */
      font-size: 12px !important;
    }

    .w-md-editor-text {
      /* padding-left: 10px !important; */
      font-size: 12px !important;
    }
  }

}

#task-detail-view-editor-wrapper-id {
  .pythoncode-button svg g {
    fill: #586069 !important;
  }

  .cpp-code-button svg g {
    fill: #586069 !important;
  }

  .w-md-editor-toolbar {
    border-bottom: 1px solid #D7DBDE !important;
    border-radius: 10px 10px 0px 0px !important;
    min-height: 40px !important;
    flex-wrap: wrap;
    height: unset !important;
    z-index: 10;
    position: relative;
    /* border-radius: 10px; */

    .pythoncode-button {
      height: 100%;
      align-items: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      /* margin: 0px; */
      padding: 0px;

      svg {
        min-height: 15px !important;
        min-width: 15px !important;
        max-width: 15px !important;
      }
    }

    .pythoncode-button:hover>svg {

      g {
        fill: var(--editor-buttons-hover) !important;
      }
    }

    .pythoncode-button svg g {
      fill: #586069 !important;
    }

    .cpp-code-button svg g {
      fill: #586069 !important;
    }
  }
}

#task-detail-view-editor-wrapper-admin-id {
  .pythoncode-button svg g {
    fill: #586069 !important;
  }

  .cpp-code-button svg g {
    fill: #586069 !important;
  }

  .w-md-editor-toolbar {
    border-bottom: 1px solid #D7DBDE !important;
    border-radius: 10px 10px 0px 0px !important;
    min-height: 40px !important;
    flex-wrap: wrap;
    height: unset !important;
    z-index: 10;
    position: relative;
    /* border-radius: 10px; */

    .pythoncode-button {
      height: 100%;
      align-items: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      /* margin: 0px; */
      padding: 0px;

      svg {
        min-height: 15px !important;
        min-width: 15px !important;
        max-width: 15px !important;
      }
    }

    .pythoncode-button:hover>svg {

      g {
        fill: var(--editor-buttons-hover) !important;
      }
    }

    .pythoncode-button svg g {
      fill: #586069 !important;
    }

    .cpp-code-button svg g {
      fill: #586069 !important;
    }
  }

  .wmde-markdown.wmde-markdown-color {
    padding-left: 15px !important;
    font-size: 13px;
  }
}

@media only screen and (max-width: 455px) {
  .footer.first {
    margin: 0px 15px;
  }

  #tickets-title-input {
    height: 32px !important;
    font-size: 12px !important;
  }

  #tickets-tickets-table-wrapper-id {
    .no-tickets-header-div {
      font-size: 10px !important;
    }
  }

  .no-done-exercises-text {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 400px) {
  .footer.first {
    .add-attachments-button {
      max-width: 120px !important;
      font-size: 11px !important;
    }

    #ticket-compose-add-button-id {
      max-width: 120px !important;
      font-size: 11px !important;
    }
  }
}




@media only screen and (min-width: 1451px) {
  #mailbox-small-tickets-compose-no-sider-id {
    max-height: 571px !important;
    height: unset !important;
    bottom: 50px;
    right: 27px !important;
  }

  #mailbox-small-tickets-compose-sider-id {
    max-height: 571px !important;
    height: unset !important;
    bottom: 50px;
    right: 27px !important;
  }

  #mailbox-small-tickets-compose-no-sider-id-with-files {
    max-height: 571px !important;
    height: unset !important;
    bottom: 50px;
    right: 27px !important;
  }

  #mailbox-small-tickets-compose-sider-id-with-files {
    max-height: 571px !important;
    height: unset !important;
    bottom: 50px;
    right: 27px !important;
  }
}



@media only screen and (max-width: 1400px) {
  #mailbox-small-tickets-compose-no-sider-id {
    max-height: 571px !important;
    height: unset !important;
  }

  #mailbox-small-tickets-compose-sider-id {
    max-height: 571px !important;
    height: unset !important;
  }

  #mailbox-small-tickets-compose-no-sider-id-with-files {
    max-height: 571px !important;
    height: unset !important;
  }

  #mailbox-small-tickets-compose-sider-id-with-files {
    max-height: 571px !important;
    height: unset !important;
  }
}

@media only screen and (max-width: 1600px) {
  #mailbox-small-tickets-compose-no-sider-id {
    /* top: 100px !important; */
    height: unset !important;
    bottom: 56px !important;

  }

  #mailbox-small-tickets-compose-sider-id {
    height: unset !important;
    bottom: 56px !important;

  }

  #mailbox-small-tickets-compose-no-sider-id-with-files {
    height: unset !important;
    bottom: 56px !important;

  }

  #mailbox-small-tickets-compose-sider-id-with-files {
    height: unset !important;
    bottom: 56px !important;

  }
}

@media only screen and (max-width: 1199px) {
  #mailbox-small-tickets-compose-no-sider-id {
    right: 31px !important;
  }

  #mailbox-small-tickets-compose-sider-id {
    right: 31px !important;
  }

  #mailbox-small-tickets-compose-no-sider-id-with-files {
    right: 31px !important;
  }

  #mailbox-small-tickets-compose-sider-id-with-files {
    right: 31px !important;
  }
}

#mailbox-small-tickets-compose-no-sider-id {
  .footer.first {
    div:first-child {
      width: 100%;
      justify-content: flex-end;
    }
  }
}

#mailbox-small-tickets-compose-sider-id {
  .footer.first {
    div:first-child {
      width: 100%;
      justify-content: flex-end;
    }
  }
}

#mailbox-small-tickets-compose-no-sider-id-with-files {
  .footer.first {
    div:first-child {
      width: 100%;
      justify-content: flex-end;
    }
  }
}

#mailbox-small-tickets-compose-sider-id-with-files {
  .footer.first {
    div:first-child {
      width: 100%;
      justify-content: flex-end;
    }
  }
}


@media only screen and (max-width: 1024px) {
  #mailbox-small-tickets-compose-no-sider-id {
    top: unset !important;
    bottom: 57px !important;
    height: unset !important;
    right: 31px !important;

    .footer.first {
      div:first-child {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }

  #mailbox-small-tickets-compose-sider-id {
    top: unset !important;
    bottom: 57px !important;
    height: unset !important;
    right: 31px !important;

    .footer.first {
      div:first-child {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }

  #mailbox-small-tickets-compose-no-sider-id-with-files {
    top: unset !important;
    bottom: 57px !important;
    height: unset !important;
    right: 31px !important;

    .footer.first {
      div:first-child {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }

  #mailbox-small-tickets-compose-sider-id-with-files {
    top: unset !important;
    bottom: 57px !important;
    height: unset !important;
    right: 31px !important;

    .footer.first {
      div:first-child {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  #mailbox-small-tickets-compose-no-sider-id {
    right: 20px !important;
    left: 20px !important;
    top: 80px !important;
    bottom: 105px !important;
    height: unset !important;
    margin-top: 0px !important;
    width: unset !important;
    max-height: 100% !important;
    max-width: unset !important;

    .footer.first {

      /* max-height: 150px !important; */
      #ticket-compose-add-button-id {
        max-width: 145px !important;
        height: 30px !important;
        border-radius: 5px !important;
        padding-left: 0px !important;
      }

      div:first-child {
        width: 100%;
        justify-content: flex-end;
      }
    }

    .body.ticket-scroll {
      height: 370px !important;
    }
  }

  #mailbox-small-tickets-compose-sider-id {
    right: 20px !important;
    left: 20px !important;
    top: 80px !important;
    bottom: 105px !important;
    height: unset !important;
    margin-top: 0px !important;
    width: unset !important;
    max-height: 100% !important;
    max-width: unset !important;

    .footer.first {

      /* max-height: 150px !important; */
      #ticket-compose-add-button-id {
        max-width: 145px !important;
        height: 30px !important;
        border-radius: 5px !important;
        padding-left: 0px !important;
      }

      div:first-child {
        width: 100%;
        justify-content: flex-end;
      }
    }

    .body.ticket-scroll {
      height: 370px !important;
    }
  }

  #mailbox-small-tickets-compose-no-sider-id-with-files {
    right: 20px !important;
    left: 20px !important;
    top: 80px !important;
    bottom: 105px !important;
    height: unset !important;
    margin-top: 0px !important;
    width: unset !important;
    max-height: 100% !important;
    max-width: unset !important;

    .footer.first {

      /* max-height: 150px !important; */
      #ticket-compose-add-button-id {
        max-width: 145px !important;
        height: 30px !important;
        border-radius: 5px !important;
        padding-left: 0px !important;
      }

      div:first-child {
        width: 100%;
        justify-content: flex-end;
      }
    }

    .body.ticket-scroll {
      height: 370px !important;
    }
  }

  #mailbox-small-tickets-compose-sider-id-with-files {
    right: 20px !important;
    left: 20px !important;
    top: 80px !important;
    bottom: 105px !important;
    height: unset !important;
    margin-top: 0px !important;
    width: unset !important;
    max-height: 100% !important;
    max-width: unset !important;

    .footer.first {

      /* max-height: 150px !important; */
      #ticket-compose-add-button-id {
        max-width: 145px !important;
        height: 30px !important;
        border-radius: 5px !important;
        padding-left: 0px !important;
      }

      div:first-child {
        width: 100%;
        justify-content: flex-end;
      }
    }

    .body.ticket-scroll {
      height: 370px !important;
    }
  }
}



@media only screen and (min-width: 650px) and (max-width: 850px) {
  #mailbox-small-tickets-compose-no-sider-id {
    width: calc(100vw - 335px) !important;
  }

  #mailbox-small-tickets-compose-no-sider-id-with-files {
    width: calc(100vw - 335px) !important;
  }
}

@media only screen and (min-width: 769px) {
  #big-mailbox-tickets-compose-sider-id {
    right: 15px !important;
  }

  #big-mailbox-tickets-compose-no-sider-id {
    width: calc(100vw - 365px) !important;
    right: 15px !important;
  }

  #big-mailbox-tickets-compose-sider-id-with-files {
    right: 15px !important;
  }

  #big-mailbox-tickets-compose-no-sider-id-with-files {
    width: calc(100vw - 365px) !important;
    right: 15px !important;
  }
}




#big-mailbox-tickets-compose-sider-id {
  top: 80px !important;
  bottom: 100px !important;
  height: unset !important;
  right: 31px !important;
  left: 31px !important;
  max-width: 100% !important;
  margin-top: 0px !important;
  width: unset !important;


  .w-md-editor.w-md-editor-show-edit {
    height: 330px !important
  }

  .w-md-editor-content {
    height: 311px !important;
  }

  .body.ticket-scroll {
    height: 350px !important;
    /*min-height: 100% !important;*/
    flex-grow: 1 !important;
  }

  .footer.first {
    max-height: 150px !important;

    div:first-child {
      width: 100%;
      justify-content: flex-end;
    }
  }

}

#big-mailbox-tickets-compose-no-sider-id {
  top: 80px !important;
  bottom: 100px !important;
  height: unset !important;
  right: 31px !important;
  left: 271px !important;
  max-width: 100% !important;
  margin-top: 0px !important;
  width: unset !important;

  .w-md-editor.w-md-editor-show-edit {
    height: 330px !important
  }

  .w-md-editor-content {
    height: 311px !important;
  }

  .body.ticket-scroll {
    height: 350px !important;
    /*min-height: 100% !important;*/
    flex-grow: 1 !important;
  }

  .footer.first {
    max-height: 150px !important;

    div:first-child {
      width: 100%;
      justify-content: flex-end;
    }
  }

}

#big-mailbox-tickets-compose-sider-id-with-files {
  top: 80px !important;
  bottom: 100px !important;
  height: unset !important;
  right: 31px !important;
  left: 31px !important;
  max-width: 100% !important;
  margin-top: 0px !important;
  width: unset !important;

  .w-md-editor.w-md-editor-show-edit {
    height: 330px !important
  }

  .w-md-editor-content {
    height: 311px !important;
  }

  .body.ticket-scroll {
    height: 350px !important;
    /*min-height: 100% !important;*/
    flex-grow: 1 !important;
  }

  .footer.first {
    max-height: 150px !important;

    div:first-child {
      width: 100%;
      justify-content: flex-end;
    }
  }

}

#big-mailbox-tickets-compose-no-sider-id-with-files {
  top: 80px !important;
  bottom: 100px !important;
  height: unset !important;
  right: 25px !important;
  left: 271px !important;
  max-width: 100% !important;
  margin-top: 0px !important;
  width: unset !important;

  .w-md-editor.w-md-editor-show-edit {
    height: 330px !important
  }

  .w-md-editor-content {
    height: 311px !important;
  }

  .body.ticket-scroll {
    height: 350px !important;
    /*min-height: 100% !important;*/
    flex-grow: 1 !important;
  }

  .footer.first {
    max-height: 150px !important;

    div:first-child {
      width: 100%;
      justify-content: flex-end;
    }
  }

}

@media only screen and (min-width: 769px) {
  #big-mailbox-tickets-compose-sider-id {
    right: 25px !important;
  }

  #big-mailbox-tickets-compose-no-sider-id {
    right: 25px !important;
  }

  #big-mailbox-tickets-compose-sider-id-with-files {
    right: 25px !important;
  }

  #big-mailbox-tickets-compose-no-sider-id-with-files {
    right: 25px !important;
  }
}

@media only screen and (max-width: 1024px) {
  #big-mailbox-tickets-compose-sider-id {
    right: 31px !important;
  }

  #big-mailbox-tickets-compose-no-sider-id {
    right: 31px !important;
  }

  #big-mailbox-tickets-compose-sider-id-with-files {
    right: 31px !important;
  }

  #big-mailbox-tickets-compose-no-sider-id-with-files {
    right: 31px !important;
  }
}


@media only screen and (min-width: 1025px) and (max-width: 1839px) {
  #big-mailbox-tickets-compose-sider-id {
    .w-md-editor.w-md-editor-show-edit {
      height: 230px !important
    }

    .w-md-editor-content {
      height: 211px !important;
    }

    .body.ticket-scroll {
      height: 450px !important;
      /*min-height: 100% !important;*/
      flex-grow: 1 !important;
    }
  }

  #big-mailbox-tickets-compose-no-sider-id {
    .w-md-editor.w-md-editor-show-edit {
      height: 230px !important
    }

    .w-md-editor-content {
      height: 211px !important;
    }

    .body.ticket-scroll {
      height: 450px !important;
      /*min-height: 100% !important;*/
      flex-grow: 1 !important;
    }
  }

  #big-mailbox-tickets-compose-sider-id-with-files {
    .w-md-editor.w-md-editor-show-edit {
      height: 230px !important
    }

    .w-md-editor-content {
      height: 211px !important;
    }

    .body.ticket-scroll {
      height: 450px !important;
      /*min-height: 100% !important;*/
      flex-grow: 1 !important;
    }
  }

  #big-mailbox-tickets-compose-no-sider-id-with-files {
    .w-md-editor.w-md-editor-show-edit {
      height: 230px !important
    }

    .w-md-editor-content {
      height: 211px !important;
    }

    .body.ticket-scroll {
      height: 450px !important;
      /*min-height: 100% !important;*/
      flex-grow: 1 !important;
    }
  }

}

@media only screen and (min-width: 1840px) {
  #big-mailbox-tickets-compose-sider-id {
    .w-md-editor.w-md-editor-show-edit {
      height: 430px !important
    }

    .w-md-editor-content {
      height: 411px !important;
    }


  }

  #big-mailbox-tickets-compose-no-sider-id {
    .w-md-editor.w-md-editor-show-edit {
      height: 430px !important
    }

    .w-md-editor-content {
      height: 411px !important;
    }

  }

  #big-mailbox-tickets-compose-sider-id-with-files {
    .w-md-editor.w-md-editor-show-edit {
      height: 430px !important
    }

    .w-md-editor-content {
      height: 411px !important;
    }

  }

  #big-mailbox-tickets-compose-no-sider-id-with-files {
    .w-md-editor.w-md-editor-show-edit {
      height: 430px !important
    }

    .w-md-editor-content {
      height: 411px !important;
    }

  }
}

@media only screen and (max-width: 767px) {
  #big-mailbox-tickets-compose-sider-id {
    right: 0px;
    bottom: 65px !important;
    top: unset !important;
    width: 100%;
    right: 31px !important;
    left: 31px !important;
    max-width: 700px !important;

    .w-md-editor.w-md-editor-show-edit {
      height: 230px !important
    }

    .w-md-editor-content {
      height: 211px !important;
    }

  }

  #big-mailbox-tickets-compose-no-sider-id {
    width: calc(100vw - 295px) !important;
    right: 0px;
    bottom: 40px;
    left: unset !important;

    .w-md-editor.w-md-editor-show-edit {
      height: 230px !important
    }

    .w-md-editor-content {
      height: 211px !important;
    }

    .w-md-editor-toolbar {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }
  }

  #big-mailbox-tickets-compose-sider-id-with-files {
    right: 0px;
    bottom: 65px !important;
    top: unset !important;
    width: 100%;
    right: 31px !important;
    left: 31px !important;
    max-width: 700px !important;

    .w-md-editor.w-md-editor-show-edit {
      height: 230px !important
    }

    .w-md-editor-content {
      height: 211px !important;
    }
  }

  #big-mailbox-tickets-compose-no-sider-id-with-files {
    width: calc(100vw - 295px) !important;
    right: 0px;
    bottom: 40px;
    left: unset !important;

    .w-md-editor.w-md-editor-show-edit {
      height: 230px !important
    }

    .w-md-editor-content {
      height: 211px !important;
    }

    .w-md-editor-toolbar {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  #big-mailbox-tickets-compose-no-sider-id {
    width: 100% !important;
  }

  #big-mailbox-tickets-compose-no-sider-id-with-files {
    width: 100% !important;
  }

}

@media only screen and (max-width: 575px) {
  #mailbox-small-tickets-compose-no-sider-id-with-files {
    min-height: 560px !important;

    .header {
      padding: 0px 15px !important;

      .icon-right {
        #close-compose-ticket-modal {
          width: 12px !important;
          height: 14px !important;
          size: 14px !important;
        }
      }

      p {
        font-size: 12px !important;
      }
    }
  }

  #mailbox-small-tickets-compose-sider-id-with-files {
    min-height: 560px !important;

    .header {
      padding: 0px 15px !important;

      .icon-right {
        #close-compose-ticket-modal {
          width: 12px !important;
          height: 14px !important;
          size: 14px !important;
        }
      }

      p {
        font-size: 12px !important;
      }
    }
  }

  #mailbox-small-tickets-compose-sider-id {
    .header {
      padding: 0px 15px !important;

      .icon-right {
        #close-compose-ticket-modal {
          width: 14px !important;
          height: 14px !important;
          size: 14px !important;
        }
      }

      p {
        font-size: 12px !important;
      }
    }
  }

  #mailbox-small-tickets-compose-no-sider-id {
    .header {
      padding: 0px 15px !important;

      .icon-right {
        #close-compose-ticket-modal {
          width: 12px !important;
          height: 14px !important;
          size: 14px !important;
        }
      }

      p {
        font-size: 12px !important;
      }
    }
  }
}

@media only screen and (max-width: 455px) {
  #mailbox-small-tickets-compose-no-sider-id-with-files {
    min-height: 550px !important;
  }

  #mailbox-small-tickets-compose-sider-id-with-files {
    min-height: 550px !important;
  }

  .email-header.status {
    max-width: 80px !important;
    min-width: 80px !important;
  }


  .table-responsive.cart-products.orders .ant-table-thead th:nth-child(2) {
    max-width: 80px !important;
    width: 80px !important;
    min-width: 80px !important;
  }
}

.email-header.title {
  /* max-width: 330px !important; */
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* margin-left: 9px !important; */
}

.email-header.status {
  max-width: 100px !important;
  min-width: 100px !important;
}

@media only screen and (min-width: 1440px) {
  .email-header.title {
    /* max-width: 530px !important;
    min-width: 530px !important; */
  }

  .table-responsive.cart-products.orders .ant-table-thead th:nth-child(1) {
    /* max-width: 530px !important;
    min-width: 530px !important; */
  }

  #admin-tickets-table-wrapper .ant-table-thead th:nth-child(1) {
    max-width: 20px !important;
    min-width: 20px !important;
  }
}

@media only screen and (min-width: 1700px) {
  .email-header.title {
    /* max-width: 730px !important;
    min-width: 730px !important; */
  }

  .table-responsive.cart-products.orders .ant-table-thead th:nth-child(1) {
    /* max-width: 730px !important;
    min-width: 730px !important; */
  }

  #admin-tickets-table-wrapper .ant-table-thead th:nth-child(1) {
    max-width: 20px !important;
    min-width: 20px !important;
  }
}

@media only screen and (min-width: 1900px) {
  .email-header.title {
    /* max-width: 930px !important;
    min-width: 930px !important; */
  }

  .table-responsive.cart-products.orders .ant-table-thead th:nth-child(1) {
    /* max-width: 930px !important;
    min-width: 930px !important; */
  }

  #admin-tickets-table-wrapper .ant-table-thead th:nth-child(1) {
    max-width: 20px !important;
    min-width: 20px !important;
  }
}

@media only screen and (min-width: 2300px) {
  .email-header.title {
    /* max-width: 1300px !important;
    min-width: 1300px !important; */
  }

  .table-responsive.cart-products.orders .ant-table-thead th:nth-child(1) {
    /* max-width: 1300px !important;
    min-width: 1300px !important; */
  }

  #admin-tickets-table-wrapper .ant-table-thead th:nth-child(1) {
    max-width: 20px !important;
    min-width: 20px !important;
  }
}

#admin-tickets-table-wrapper .ant-table-thead th:last-child {
  padding-left: 0px !important;
}

#admin-tickets-table-wrapper {
  tr td:last-child {
    padding-left: 0px !important;
  }

  .admin-cells-hover {
    &:hover {
      color: var(--primary-buttons-color) !important;
      text-decoration: underline !important;
    }
  }
}

.table-responsive.cart-products.orders .ant-table-thead th:nth-child(2) {
  max-width: 100px !important;
  width: 100px !important;
  min-width: 100px !important;
}

.table-responsive.cart-products.orders .ant-table-thead th:nth-child(3) {
  max-width: 100px !important;
  min-width: 100px !important;
  width: 100px !important;
}

@media only screen and (max-width: 455px) {

  .email-header.status {
    max-width: 80px !important;
    min-width: 80px !important;
  }

  .table-responsive.cart-products.orders .ant-table-thead th:nth-child(2) {
    max-width: 80px !important;
    width: 80px !important;
    min-width: 80px !important;
  }
}

@media only screen and (max-width: 991px) {
  #tickets-email-wrapper-id {
    gap: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  #tickets-email-wrapper-id {
    flex-direction: column;
    gap: 0px !important;
  }

  .trigger-col>button {
    margin-bottom: 5px !important;
  }

  .email-header {
    max-width: 230px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .resize-compose-ticket-modal.tickets {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  #tickets-routes-div-wrapper-id {
    padding-right: 270px !important;
  }
}

.no-done-exercises-text {
  color: #878EAA;
  font-weight: 600;
  font-size: 11px;
}

.no-done-exercises-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 130px;
}

#referral-code-packages-id {
  .ant-table-tbody>tr:last-child {
    border-radius: 0px 0px 10px 10px !important;
  }
}

#orders-history-table-id {
  .ant-table-tbody>tr:last-child {
    border-radius: 0px 0px 10px 10px !important;
  }
}

#editor-wrapper-big-screen {
  .w-md-editor-text-pre>code {
    line-height: unset !important;
  }

  .w-md-editor-text-input {
    line-height: unset !important;
  }
}

.send-to-verify-text-info {
  color: #5D5F79 !important;
  font-weight: 500;
  font-size: 13px;
}

.send-to-verify-text-info-small {
  color: #5D5F79 !important;
  font-weight: 300;
  font-size: 10px;
}

.send-to-verify-text-info-icon-wrapper {
  background-color: #AFB4CD;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 100%;
}

.send-to-verify-text-info-and-icon-main-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  background-color: #F6F8FA;
  border-radius: 10px;
  padding: 10px 15px;
}

.send-to-verify-texts-info-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media only screen and (max-width: 455px) {
  .send-to-verify-text-info {
    font-size: 10px;
  }

  .send-to-verify-text-info-small {
    font-size: 8px;
  }
}

.anticon.anticon-right {
  background-image: url('../../static/img/icon/pagination-arrow-right.svg') !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  height: 100%;
  width: 100%;
  margin-left: 1px;

  svg {
    display: none !important;
  }
}

.anticon.anticon-left {
  background-image: url('../../static/img/icon/pagination-arrow-left.svg') !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  height: 100%;
  width: 100%;

  svg {
    display: none !important;
  }
}

#tickets-nav-ul {
  a {
    color: #9399B5 !important;

    &:hover {
      color: var(--primary-buttons-color) !important;
    }
  }

  a:hover {
    color: var(--primary-buttons-color) !important;
  }

  a.active {
    color: var(--primary-buttons-color) !important;
  }

  #all-active-nav-link-id {
    color: var(--primary-buttons-color) !important;

    &:hover {
      color: var(--primary-buttons-color) !important;
    }
  }

  #closed-active-nav-link-id {
    color: var(--primary-buttons-color) !important;

    &:hover {
      color: var(--primary-buttons-color) !important;
    }
  }

  #open-active-nav-link-id {
    color: var(--primary-buttons-color) !important;

    &:hover {
      color: var(--primary-buttons-color) !important;
    }
  }
}

#tickets-email-wrapper-id {
  .ticket-view-card {
    border-radius: 10px !important;
    box-shadow: 0px 3px 6px #D7DBDE !important;

    .ant-card-head-title>div {
      width: 100%;
    }

    .ant-card-head {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .ant-card-body {
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-top: 26px !important;
      padding-bottom: 20px !important;

      @media only screen and (max-width: 455px) {
        padding-top: 15px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-bottom: 15px !important;
      }
    }

    .reply-list {
      display: flex;
      flex-direction: column;
      gap: 9px;

      @media only screen and (max-width: 455px) {
        gap: 7px;
      }
    }


    .reply-text {
      margin-top: 5px;
      padding-left: 10px;
      word-break: break-word;

      code {
        word-break: break-all !important;
        white-space: break-spaces !important;
      }

      .wmde-markdown.wmde-markdown-color {
        line-height: 15px !important;
        /* font-size: 12px !important;
        font-weight: 400 !important;
        color: #5D5F79 !important; */
        font-size: 13px !important;
        color: #5D5F79 !important;
        font-weight: 400 !important;

      }

      .wmde-markdown.wmde-markdown-color ul,
      .wmde-markdown.wmde-markdown-color ul>li,
      .wmde-markdown.wmde-markdown-color blockquote>p,
      .wmde-markdown.wmde-markdown-color code,
      .wmde-markdown.wmde-markdown-color ol,
      .wmde-markdown.wmde-markdown-color table>tbody {
        font-size: 13px !important;
        color: #5D5F79 !important;
        font-weight: 400 !important;
        font-family: 'Red Hat Display', sans-serif !important;
      }
    }

    .reply-text.admin {
      font-size: 13px !important;
    }


    .message-subject {
      font-size: 13px !important;
      line-height: 17px !important;
      color: #232529 !important;
      font-weight: bold !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      width: 100% !important;
    }

    .status-badge-tickets {
      font-size: 13px !important;
      line-height: 17px !important;
      color: #232529 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      margin-right: 10px;

      @media only screen and (max-width: 455px) {
        margin-right: 0px;

        svg {
          width: 10px !important;
          height: 10px !important;
          size: 10px !important;
        }
      }
    }

    .message-subject-text {
      max-width: 80% !important;
      height: 100%;
      white-space: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @media only screen and (max-width: 901px) {
        max-width: 60% !important;
      }
    }

    .ant-card-head-title {
      padding: 13px 0px !important;
    }

    .reply-view__single {
      background-color: #F6F8FA !important;
      border-radius: 10px !important;
      padding: 10px 15px !important;

      .avatar-and-check-wrapper {
        position: relative;
        height: 30px;
        width: 30px;
      }

      .icon-wrapper-reply {
        border-radius: 100%;
        background-color: #5FC69A;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .reply-avatar {
        position: absolute;
        max-height: 15px !important;
        max-width: 15px !important;
        font-size: 5px !important;
        margin-right: 0px !important;
        right: -4px !important;
        bottom: -4px !important;
      }

      .reply-avatar-comment {
        margin-right: 0px !important;
      }
    }

    .reply-view__single.admin {
      background-color: #F0F7FF !important;
      margin-left: 45px !important;
    }

    .reply-avatar-and-name-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
    }

    #ticket-comment-id {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .reply-text-and-time-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0px;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .reply-user-name {
      font-size: 12px !important;
      color: #5D5F79 !important;
      font-weight: 500 !important;
      margin-bottom: -2px !important;
    }

    .reply-user-name.admin {
      font-size: 13px !important;
    }

    .reply-time {
      font-size: 10px !important;
      color: #5D5F79 !important;
      font-weight: 300 !important;
      /* margin-top: -3px !important; */
    }

  }
}

#open-ticket-editor-id {
  .w-md-editor-bar {
    margin-top: -30px !important;
    color: #D7DBDE !important;
    fill: #D7DBDE !important;
    right: 3px !important;
  }
}

@media only screen and (max-width: 455px) {
  #task-details-send-button-id {
    max-width: 145px !important;
    height: 30px !important;
    border-radius: 5px !important;
    font-size: 10px !important;
  }

  #open-ticket-editor-id {
    .w-md-editor-text {
      font-size: 10px !important;
    }
  }

  #open-ticket-editor-id {
    .w-md-editor-text {
      padding-top: 11px !important;
      padding-left: 10px !important;
    }

    & .w-md-editor-toolbar {
      ul:first-child {
        padding-left: 5px !important;
      }

      ul:last-child {
        padding-left: 5px !important;
      }
    }
  }

  #task-detail-view-editor-wrapper-id {
    .preview-text {
      padding-left: 9px !important;
      font-size: 10px !important;
    }

    .wmde-markdown.wmde-markdown-color {
      padding-left: 9px !important;
      font-size: 10px !important;
    }

    .wmde-markdown.wmde-markdown-color ul,
    .wmde-markdown.wmde-markdown-color ul>li,
    .wmde-markdown.wmde-markdown-color blockquote>p,
    .wmde-markdown.wmde-markdown-color code,
    .wmde-markdown.wmde-markdown-color ol,
    .wmde-markdown.wmde-markdown-color table>tbody {
      font-size: 9px !important;
    }
  }

  #tickets-email-wrapper-id {
    .ticket-view-card {
      .message-subject-text {
        -webkit-line-clamp: 2 !important;
        max-width: 50% !important;
      }

      .ant-card-extra {
        display: none !important;
      }

      .ant-card-head-title>div {
        width: 100%;
      }
    }
  }

  #tickets-email-wrapper-id {
    .ticket-view-card {
      .reply-text {
        margin-top: 2px !important;

        .wmde-markdown.wmde-markdown-color {
          font-size: 10px !important;

          .wmde-markdown.wmde-markdown-color ul,
          .wmde-markdown.wmde-markdown-color ul>li,
          .wmde-markdown.wmde-markdown-color blockquote>p,
          .wmde-markdown.wmde-markdown-color code,
          .wmde-markdown.wmde-markdown-color ol,
          .wmde-markdown.wmde-markdown-color table>tbody {
            font-size: 10px !important;
            font-family: 'Red Hat Display', sans-serif !important;
          }
        }
      }
    }

    .ticket-view-card {
      .reply-avatar-and-name-wrapper {
        gap: 7px !important;
      }

      .reply-view__single.admin {
        margin-left: 10px !important;
      }

      .reply-view__single {
        padding: 9px 10px !important;
      }
    }

    .icon-wrapper-reply {
      width: 25px !important;
      height: 25px !important;

      svg {
        width: 15px !important;
        height: 15px !important;
        size: 15px !important;
      }
    }

    .ticket-view-card {
      .reply-view__single {
        .reply-avatar {
          max-width: 12px !important;
          max-height: 12px !important;
          right: 2px !important;
          bottom: 4px !important;
        }
      }
    }

    & .ticket-view-card {
      .reply-user-name {
        font-size: 10px !important;
      }

      .reply-time {
        font-size: 9px !important;
      }

      .reply-avatar-comment {
        width: 25px !important;
        height: 25px !important;
      }
    }
  }
}


#selected-user-tickets-li {
  background-color: transparent !important;
  border: 0.5px solid #9399B5 !important;
  margin-bottom: 5px !important;
  border-radius: 7px !important;
  color: #9399B5 !important;
  font-size: 11px !important;
  line-height: 14px !important;
  padding: 10px !important;
  width: 170px !important;
  word-break: break-all !important;

  a {
    background-color: transparent !important;
    padding: 0px !important;
    margin: 0px !important;
    color: #9399B5 !important;
    border: none !important;
    height: unset !important;
    width: unset !important;
  }
}

#selected-user-tickets-li-blue {
  color: #2280F4 !important;
  background-color: #F0F7FF !important;
  border: 0.5px solid #2280F4 !important;
  margin-bottom: 5px !important;
  border-radius: 7px !important;
  font-size: 11px !important;
  line-height: 14px !important;
  padding: 10px !important;
  width: 170px !important;
  word-break: break-all !important;

  @media only screen and (max-width: 1023px) {
    width: 100% !important;
  }

  a {
    color: #2280F4 !important;
    padding: 0px !important;
    margin: 0px !important;
    background-color: #F0F7FF !important;
    border: none !important;
    height: unset !important;
    width: unset !important;
  }
}

#selected-user-tickets-ul {
  gap: 5px !important;
  margin-bottom: 10px !important;

  #dott-open {
    border-radius: 100%;
    background-color: #9399B5 !important;
    min-width: 7px;
    min-height: 7px;
    max-width: 7px;
    max-height: 7px;
  }

  #dott-closed {
    border-radius: 100%;
    background-color: #5FC69A !important;
    min-width: 7px;
    min-height: 7px;
    max-width: 7px;
    max-height: 7px;
  }

  #selected-user-tickets-status-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }

  #selected-user-tickets-ticket-title-id {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    display: -webkit-box !important;
  }

  .ant-space.ant-space-vertical {
    gap: 4px !important;
  }
}

#selected-user-tickets-buttons-wrapper {
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid #B5BAD2;
  gap: 5px;
  padding: 15px;
  padding-top: 20px;
}

#selected-user-tickets-email-nav-id {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px;
  padding-bottom: 0px;
  padding-top: 0px;
}

#selected-user-tickets-course-names-wrapper-id {
  display: flex !important;
  flex-direction: column !important;
  gap: 5px !important;
  padding: 0px 15px !important;
  /* font-size: 13px !important; */
  color: #5D5F79 !important;
  font-size: 11px !important;
  color: #5D5F79 !important;
  font-weight: 400 !important;
  line-height: 14px !important;
}

#selected-user-tickets-user-names-wrapper-id {
  display: flex !important;
  flex-direction: column !important;
  gap: 5px !important;
  padding: 0px 15px !important;
  font-size: 13px !important;
  color: #5D5F79 !important;
  line-height: 17px !important;
}


#task-detail-view-id {
  #selected-user-tickets-course-card-id {
    margin-left: 25px;
    background-color: transparent;

    .ant-card-body {
      padding: 0px !important;
      box-shadow: 0px 3px 6px #00000029;
      max-width: 200px !important;
      border-radius: 10px !important;
      background-color: #FFFFFF;
    }
  }
}

@media only screen and (max-width: 1023px) {
  #task-detail-view-id {
    flex-direction: column-reverse;


    #selected-user-tickets-course-card-id {
      margin-left: 0px !important;
      margin-bottom: 20px;

      .ant-card-body {
        width: 100% !important;
        max-width: 100% !important;

        #selected-user-tickets-course-names-wrapper-id {
          text-align: center;
        }

        #selected-user-tickets-li {
          width: 100% !important;
        }

        #selected-user-tickets-button-new-ticket-id {
          width: 100% !important;
        }

        #selected-user-tickets-button-id {
          width: 100% !important;
        }
      }
    }
  }
}

#selected-user-tickets-button-id {
  background-color: transparent !important;
  border: 0.5px solid #AFB4CD !important;
  color: #9399B5 !important;
  font-size: 11px !important;
  font-weight: bold !important;
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
  padding: 16px 10px !important;
  border-radius: 7px !important;
  justify-content: flex-start !important;
  width: 170px !important;
  height: 30px !important;

  &:hover {
    background-color: #F0F7FF !important;
    color: #2280F4 !important;
    border: 0.5px solid #2280F4 !important;
  }
}

#selected-user-tickets-button-new-ticket-id {
  background-color: #2280F4 !important;
  border: none !important;
  color: #FFFFFF !important;
  font-size: 11px !important;
  font-weight: bold !important;
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
  padding: 16px 10px !important;
  border-radius: 7px !important;
  justify-content: flex-start !important;
  width: 170px !important;
  height: 30px !important;

  &:hover {
    border: none !important;
    background-color: #061A2C !important;
  }

  &:focus {
    border: none !important
  }
}

#task-detail-view-headers-wrapper-id {
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid #AFB4CD !important;
}

#status-and-selects-wrapper-id {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

#task-name-and-open-close-task-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #AFB4CD !important;
  padding-bottom: 12px !important;
  /* padding-top: 7px; */

  .message-subject-text {
    padding: 0px 20px;
    -webkit-line-clamp: 2 !important;
    line-height: 17px !important;
    font-size: 13px !important;
    font-weight: bold;
    color: #232529;
  }

  #close-task-button-admin-id {
    margin-right: 20px !important;
    border-radius: 10px !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    background-color: #CF4A40 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 12px !important;
    width: 140px !important;
    height: 30px !important;
  }

  #task-details-send-button-id {
    margin-right: 20px !important;
    border-radius: 10px !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    background-color: #2280f4 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 12px !important;
    width: 140px !important;
    height: 30px !important;
  }

  #open-task-button-admin-id {
    margin-right: 20px !important;
    border-radius: 10px !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    background-color: #5FC69A !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 12px !important;
    width: 140px !important;
    height: 30px !important;
  }

  .ant-card-head-title span {
    font-size: 12px !important;
    color: #FFFFFF !important;
  }
}

#admin-tickets-table-wrapper {
  .ant-select.ant-select-single.ant-select-show-arrow:first-child {
    max-width: 130px !important;
    /* margin-left: 20px; */
    height: 35px !important;

    .ant-select-selector {
      background-color: #FBFBFB !important;
      border-radius: 10px !important;
      max-height: 35px !important;
      padding: 0px !important;
      width: 130px;
    }

    .ant-select-selection-placeholder {
      align-items: center;
      display: flex;
      line-height: 12px;
      font-size: 12px;
      color: #AFB4CD;
      font-style: italic;
      padding-left: 12px !important;
    }

    .ant-select-selection-item {
      padding: 0px !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      white-space: break-spaces;
      word-break: break-word;
      padding-left: 12px !important;
      font-size: 12px !important;
    }

    #teacher-names-select-id {
      line-height: 12px;
      font-size: 10px;
      color: #232529;
      font-weight: 500;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 60px;
    }

    .admin-names-select {
      margin-right: 0px !important;
    }
  }
}

.ant-select-item-option-content {
  .admin-names-select {
    display: none !important;
  }
}

#status-and-selects-wrapper-id {

  .ant-select.ant-select-single.ant-select-show-arrow:first-child {
    max-width: 130px !important;
    margin-left: 20px;
    height: 35px !important;

    .ant-select-selector {
      background-color: #FBFBFB !important;
      border-radius: 10px !important;
      max-height: 35px !important;
      padding: 0px !important;
      width: 130px;
      border-color: #D7DBDE !important;
    }

    .ant-select-selection-placeholder {
      align-items: center;
      display: flex;
      font-size: 12px !important;
      font-style: italic !important;
      color: #AFB4CD !important;
    }

    .ant-select-selection-item {
      padding: 0px !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 9px !important;
      gap: 5px;
      /* white-space: break-spaces;
      word-break: break-all;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; */
    }

    #teacher-names-select-id {
      line-height: 12px;
      font-size: 10px;
      color: #232529;
      font-weight: 500;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 60px;
    }

    @media only screen and (max-width: 1023px) {
      #teacher-names-select-id {
        max-width: 100%;
      }

      .ant-select-selection-item {
        justify-content: flex-start;
      }
    }

    .admin-names-select {
      margin-right: 0px !important;
    }
  }

  .ant-select.ant-select-single.ant-select-show-arrow:nth-child(2) {
    max-width: 230px !important;
    min-width: 230px !important;
    margin-right: 20px;
    height: 35px !important;

    .ant-select-selector {
      background-color: #FBFBFB !important;
      border-radius: 10px !important;
      max-height: 35px !important;
      border-color: #D7DBDE !important;
    }

    .ant-select-selection-placeholder {
      align-items: center;
      display: flex;
      font-size: 12px !important;
      font-style: italic !important;
      color: #AFB4CD !important;
    }

    .ant-select-selection-search {
      padding: 0px !important;
      margin: 0px !important;
    }

    .ant-select-selection-item {
      padding-left: 0px !important;
      margin-left: 0px !important;
    }
  }

  @media only screen and (max-width: 1023px) {
    .ant-select.ant-select-single.ant-select-show-arrow:first-child {
      max-width: 100% !important;
      min-width: 100% !important;
      margin: 0px !important;
      padding: 0px 20px !important;

      .ant-select-selector {
        width: 100%;
        min-width: 100% !important;
      }

      .ant-select-selection-search {
        margin: 0px !important;
      }

      .ant-select-selection-placeholder {
        padding: 0px !important;
        margin: 0px !important;
        padding-left: 12px !important;
      }

      .ant-select-arrow {
        margin: 0px !important;
        top: 11px !important;
        right: 30px !important;
      }
    }

    .ant-select.ant-select-single.ant-select-show-arrow:nth-child(2) {
      max-width: 100% !important;
      min-width: 100% !important;
      margin: 0px !important;
      padding: 0px 20px !important;

      .ant-select-selection-search {
        margin: 0px !important;
      }

      .ant-select-selection-placeholder {
        padding: 0px !important;
        margin: 0px !important;
      }

      .ant-select-arrow {
        margin: 0px !important;
        top: 11px !important;
        right: 30px !important;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .ant-select.ant-select-single.ant-select-show-arrow:first-child {
    .ant-select-arrow {
      margin: 0px !important;
      top: 11px !important;
      right: 30px !important;
    }
  }

  .ant-select.ant-select-single.ant-select-show-arrow:nth-child(2) {
    .ant-select-arrow {
      margin: 0px !important;
      top: 11px !important;
      right: 25px !important;

      svg {
        width: 18px !important;
        height: 18px !important;
        size: 18px !important;
      }
    }
  }

  #ticket-compose-add-button-id {
    font-size: 10px !important;
  }
}


@media only screen and (max-width: 1023px) {
  #status-and-selects-wrapper-id {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
}

#task-detail-view-col-admin-id {
  width: 100%;

  .ticket-view-card {
    .ant-card-head {
      padding: 0px !important;
    }
  }

  .message-subject {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .ant-card-head {
    border-bottom: none !important;
  }

  .preview-text {
    padding-left: 16px !important;
    line-height: 17px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #262626 !important;
  }
}

#task-detail-view-col-student-id {
  width: 100% !important;

  .ant-card-head-title {
    min-width: 100% !important;
  }
}

.trigger-col.admin {
  max-width: 200px !important;

  @media only screen and (max-width: 455px) {
    max-width: 100% !important;
  }

  .mail-sideabr .ant-card {
    max-width: 200px !important;
    min-height: 1px !important;

    @media only screen and (max-width: 455px) {
      max-width: 100% !important;
    }
  }

  #tickets-nav-ul {
    a {
      max-width: 170px !important;

      @media only screen and (max-width: 455px) {
        max-width: 100% !important;
      }

      &:hover {
        color: var(--primary-buttons-color) !important;

        .badge.badge-primary {
          background-color: var(--primary-buttons-color) !important;
          color: #FFFFFF;
        }
      }
    }

  }
}

#tickets-nav-ul {
  a {
    &:hover {
      color: var(--primary-buttons-color) !important;

      .badge.badge-primary {
        background-color: var(--primary-buttons-color) !important;
        color: #FFFFFF;
      }
    }
  }
}

#status-and-was-answered-div {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #232529;
  font-size: 12px;
  padding-right: 20px;

  #status-bagdes-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 1023px) {
    #status-bagdes-col {
      flex-direction: row;
      gap: 10px;

      #no-answer {
        min-width: 85px;
      }
    }
  }

  #dott-open {
    border-radius: 100%;
    background-color: #9399B5 !important;
    min-width: 7px;
    min-height: 7px;
    max-width: 7px;
    max-height: 7px;
  }

  #dott-closed {
    border-radius: 100%;
    background-color: #5FC69A !important;
    min-width: 7px;
    min-height: 7px;
    max-width: 7px;
    max-height: 7px;
  }

  #selected-user-tickets-status-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    text-align: start;
    width: 100%;
  }
}

#no-answer {
  color: #EC8B4A;
  background-color: rgba(236, 139, 74, 0.2);
  border-radius: 5px;
  width: 85px;
  height: 18px;
  font-size: 10px;
  text-align: center;
}

#task-detail-view-col-student-id {
  .ant-card-head-wrapper {
    min-height: 100% !important;
  }

  .ant-card-head-title {
    min-width: 100% !important;
    min-height: 100% !important;
    padding: 0px !important;
  }
}

#tickets-email-wrapper-id {
  #task-detail-view-col-student-id {
    .ticket-view-card {
      .ant-card-head-title {
        min-width: 100% !important;
        min-height: 100% !important;
        padding: 0px !important;
        min-height: 48px !important;
        display: flex !important;
        align-items: center !important;
      }
    }
  }
}

.change-question-button:disabled {
  color: #D7DBDE !important;
}

@media only screen and (max-width: 455px) {
  .change-question-button {
    font-size: 11px !important;
    padding-top: 0px !important;
  }
}

#error-in-question-modal-title {
  height: 40px !important;
  font-size: 13px !important;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  border-radius: 10px !important;
  border: 1px solid #D7DBDE !important;
  padding: 0px 17px !important;
  color: #AFB4CD !important;
  font-size: 13px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
}

.error-in-question-modal {
  .ant-modal-close-x {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .ant-modal-footer {
    padding-right: 24px !important;
  }

  .ant-modal-header {
    border-bottom: 1px solid #D7DBDE !important;
  }

  .ant-input {
    border: 1px solid #D7DBDE !important;
    border-radius: 10px !important;
  }
}

#error-in-question-modal-description {
  padding-left: 17px !important;
}

#error-in-question-modal-description::placeholder {
  color: #AFB4CD !important;
  font-size: 13px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  padding-left: 0px !important;
}

#close-compose-ticket-modal {
  line {
    stroke-width: 3px !important;
  }
}

#resize-compose-ticket-modal {
  polyline {
    stroke-width: 3px !important;
  }

  line {
    stroke-width: 3px !important;
  }
}

.table-responsive.cart-products.orders.orders {
  #order-number {
    font-size: 12px !important;
    color: #232529;
    font-weight: bold !important;
  }

  tr td:nth-child(1) {
    padding-left: 25px !important;
  }

  tr td:nth-child(2) {
    font-size: 12px !important;
    color: #232529;
    font-weight: bold !important;
  }

  tr td:nth-child(3) {
    font-size: 12px !important;
    color: #232529;
    font-weight: bold !important;
  }

  tr td:nth-child(4) {
    font-size: 12px !important;
    color: #232529;
    font-weight: bold !important;
  }

  tr td:nth-child(5) {
    font-size: 12px !important;
    color: #232529;
    font-weight: bold !important;
  }
}

.openRateModalsText {
  font-style: italic;
}

.openRateModalsText:hover {
  text-decoration: underline !important;
}

@media only screen and (max-width: 455px) {
  .openRateModalsText {
    font-size: 8px !important;
  }

  #openRateModalIcon {
    max-width: 8px !important;
    max-height: 8px !important;
    size: 8px !important;
  }
}

#admin-table-tickets-id .ant-table-thead th:nth-child(3) {
  max-width: 220px !important;
  min-width: 100px !important;
  /* width: 220px !important; */
}

@media only screen and (max-width: 901px) {
  #tickets-tickets-table-wrapper-id {
    .table-responsive.cart-products.orders.tickets {
      tr td:last-child {
        max-width: 100px !important;
        white-space: wrap !important;
        padding-right: 25px !important;
        padding-left: 5px !important;
      }

      tr th:last-child {
        max-width: 100px !important;
        white-space: wrap !important;
        padding-right: 25px !important;
        padding-left: 5px !important;
      }
    }
  }

  #tickets-tickets-table-wrapper-id {
    .table-responsive.cart-products.orders.tickets {
      #admin-table-tickets-id tr td:last-child {
        max-width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 455px) {
  #tickets-tickets-table-wrapper-id {
    .table-responsive.cart-products.orders.tickets {
      tr td:last-child {
        padding-left: 16px !important;
      }

      tr th:last-child {
        padding-left: 16px !important;
      }
    }
  }

  #tickets-nav-icons-id {
    width: 10px !important;
    height: 10px !important;
    size: 10px !important;
  }

  #tickets-email-wrapper-id {
    & .ticket-view-card {
      .message-subject {
        font-size: 10px !important;
      }

      .status-badge-tickets {
        font-size: 10px !important;
        gap: 7px !important;
      }
    }
  }

  #tickets-tickets-table-wrapper-id {
    .table-responsive.cart-products.orders.tickets {


      #all-tickets-title-first-column>a {
        font-size: 10px !important;
      }

      .email-header.status {
        font-size: 10px !important;
      }

      .email-header>h1 {
        font-size: 10px !important;
      }

      .email-time {
        font-size: 10px !important;
      }

      tr td:nth-child(2) {
        font-size: 10px !important;
      }

      .ant-table-thead>tr>th {
        font-size: 10px !important;
      }

      tr td:nth-child(1) {
        padding-left: 15px !important;
      }

      .ant-table-thead>tr>th:first-child {
        padding-left: 15px;
      }
    }
  }

  .ant-select-item-option-content {
    font-size: 11px !important;
  }

  #preview-text-title-id {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 375px) {
  #mailbox-small-tickets-compose-no-sider-id {
    .body.ticket-scroll {
      height: 310px !important;
    }
  }

  #mailbox-small-tickets-compose-sider-id {
    .body.ticket-scroll {
      height: 310px !important;
    }
  }

  #mailbox-small-tickets-compose-no-sider-id-with-files {
    .body.ticket-scroll {
      height: 310px !important;
    }
  }

  #mailbox-small-tickets-compose-sider-id-with-files {
    .body.ticket-scroll {
      height: 310px !important;
    }
  }
}

.custom-notification {
  .ant-notification-notice-message {
    font-size: 13px !important;
    color: #232529 !important;
    font-weight: 500 !important;
    margin-top: -5px !important;
  }

  .ant-notification-notice-description {
    margin-top: -5px !important;
    font-size: 11px !important;
    color: #232529 !important;
    font-weight: 400 !important;
  }
  
  @media only screen and (max-width: 390px) {
    #open-ticket-editor-id {
      & .w-md-editor-toolbar {
        ul:last-child {
          position: absolute;
          right: 11px;
          bottom: -5px;
        }
      }
    }
  }
}